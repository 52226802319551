import { CustomerDataResponse, CustomerDataRequest, CustomerUpdateRequest } from "../types/types";
import { api } from "./api";
import { getEnv } from "../getEnv";

const basePath = `${getEnv().API_PATH_GROUPS}/groups/customers`;

const customersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCustomers: builder.query<CustomerDataResponse[], void>({
      query: () => ({
        url: `${basePath}/all`,
        method: "GET",
      }),
      providesTags: ["Customer"],
    }),
    getCustomerData: builder.query<CustomerDataResponse, string | void>({
      query: (id) => ({
        url: id ? `${basePath}/${id}` : basePath,
        method: "GET",
      }),
      providesTags: ["Customer"],
    }),
    createCustomer: builder.mutation<CustomerDataResponse, CustomerDataRequest>({
      query: (req) => ({
        url: basePath,
        method: "POST",
        body: req,
      }),
      invalidatesTags: ["Customer"],
    }),
    updateCustomerData: builder.mutation<
        CustomerDataResponse,
        { id: string; request: CustomerUpdateRequest }
    >({
      query: ({ id, request }) => ({
        url: `${basePath}/${id}`,
        method: "PUT",
        body: request,
      }),
      invalidatesTags: ["Customer"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCustomersQuery,
  useGetCustomerDataQuery,
  useCreateCustomerMutation,
  useUpdateCustomerDataMutation,
} = customersApi;
