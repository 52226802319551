import { Button, Grid, styled, Typography } from "@mui/material";
import React, { useEffect, useState, useRef, ReactNode } from "react";
import { debounce } from "lodash";

const ClampableTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "clamped" && prop !== "lines" && prop !== "overflowMode",
})<{ clamped: boolean; lines: number}>(({ clamped, lines}) => ({
  ...(clamped && {
    "&": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: lines,
      WebkitBoxOrient: "vertical",
    },
  }),
}));

interface IClampedTypography {
  text: string;
  lessText?: string;
  moreText?: string;
  lines?: number;
  onClick?: () => void;
}
const ClampedTypography = (props: IClampedTypography) => {
  const {
    text,
    lessText = "Read less",
    moreText = "Read More",
    lines = 3,
  } = props;
  const [clamped, setClamped] = useState(true);
  const [showReadMore, setShowReadMore] = useState(true);
  const containerRef = useRef<HTMLElement>(null);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setClamped(!clamped);
  };

  //see https://javascript.plainenglish.io/creating-a-read-more-collapsible-component-in-reactjs-6d55fbc4ff0f
  useEffect(() => {
    const hasClamping = (el: HTMLElement) => {
      const { clientHeight, scrollHeight } = el;
      return clientHeight !== scrollHeight;
    };

    const checkButtonAvailability = () => {
      if (containerRef && containerRef.current) {
        const wasClamped = clamped;

        if (!wasClamped) setClamped(true);

        setShowReadMore(hasClamping(containerRef.current));

        if (!wasClamped) setClamped(false);
      }
    };

    const debouncedCheck = debounce(checkButtonAvailability, 50);

    checkButtonAvailability();
    window.addEventListener("resize", debouncedCheck);

    return () => {
      window.removeEventListener("resize", debouncedCheck);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  return (
    <Grid container justifyContent="flex-end" alignItems={"stretch"} spacing={1}>
      <Grid item xs={12}>
        <ClampableTypography
          ref={containerRef}
          lines={lines}
          clamped={clamped}
        >
          {text}
        </ClampableTypography>
      </Grid>
      <Grid item>
        {showReadMore && (
          <Button onClick={props?.onClick ? props.onClick : handleClick}>
            {clamped ? moreText : lessText}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default ClampedTypography;
