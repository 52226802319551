import {FC} from 'react';
import * as React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";

interface BrandingbarProps {
}

//menu items
const navItems: string[] = [];
const Brandingbar: FC<BrandingbarProps> = (props) => {

    const [mobileOpen, setMobileOpen] = React.useState(false);
    const matches = useMediaQuery("(min-width:600px)");

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
            <AppBar component="nav" style={{position: 'relative'}}>
                <Toolbar sx={{height: "53px"}}>
                    <IconButton
                        color="primary"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{display: "none"}}
                        //sx={{ mr: 2, display: { sm: "none" } }}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Box
                        href="/"
                        component="a"
                        sx={{
                            display: {xs: "block"},
                            ml: matches ? 10 : undefined,
                        }}
                    >
                        <Box
                            height={45}
                            alt="Masterment - Home"
                            src={"/assets/images/masterment_full_logo.svg"}
                            component="img"
                        />
                    </Box>
                    <Box sx={{display: {xs: "none", sm: "block"}}}>
                        {navItems.map((item) => (
                            <Button key={item}>{item}</Button>
                        ))}
                    </Box>
                </Toolbar>
            </AppBar>
    );
}


export default Brandingbar


