import React, { useEffect, useState } from "react";
import {
    Autocomplete,
    Button,
    Grid,
    Pagination,
    TextField,
    Typography,
    CircularProgress,
    Fade,
} from "@mui/material";
import { useIntl } from "react-intl";
import { useGetCustomersQuery } from "../../services/customers";
import usePagination from "../../hooks/usePagination";
import { CustomerDataResponse } from "../../types/types";
import CustomerSummaryCard from "../../components/customer/CustomerSummaryCard";
import AddCustomerDialog from "../../components/customer/AddCustomerDialog";

const PAGE_SIZE = 9;

const CustomerManagement: React.FC = () => {
    const intl = useIntl();
    const { data: customers, isLoading } = useGetCustomersQuery();
    const [filteredItems, setFilteredItems] = useState<CustomerDataResponse[]>([]);

    const [open, setOpen] = useState<boolean>(false);
    const handleOpenDialog = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const { start, end, handlePageChange, resetState } = usePagination(PAGE_SIZE);

    const handleSearch = (
        e: React.SyntheticEvent<Element, Event>,
        value: string
    ) => {
        const search = value.toLowerCase();

        setFilteredItems(
            customers?.filter(
                (val) =>
                    search.length === 0 ||
                    val.name.toLowerCase().indexOf(search) > -1 ||
                    val.approvedDomains.toLowerCase().indexOf(search) > -1
            ) ?? []
        );
        resetState();
    };

    useEffect(() => {
        if (customers) {
            setFilteredItems(customers);
        }
    }, [customers]);

    return (
        <Grid
            sx={{ pt: 2 }}
            container
            rowGap={2}
            spacing={3}
            justifyContent="flex-start"
        >
            <Grid item xs={12} md={8}>
                <Autocomplete
                    disablePortal
                    onInputChange={handleSearch}
                    id="customer-selector"
                    options={customers ?? []}
                    fullWidth
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={intl.formatMessage({
                                id: "CustomerManagement.Common.HelpText.Search",
                            })}
                        />
                    )}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option.id}>
                                <Typography variant="body1" color="text.secondary">
                                    {option.name}
                                </Typography>
                            </li>
                        );
                    }}
                />
            </Grid>
            <Grid
                container
                item
                xs={12}
                md={4}
                alignSelf="center"
                justifyContent="flex-end"
            >
                <Button onClick={handleOpenDialog} variant="outlined">
                    {intl.formatMessage({
                        id: "CustomerManagement.AddCustomer.HelpText.CreateCustomer",
                    })}
                </Button>
            </Grid>
            <Grid container item md={12} spacing={2}>
                {isLoading ? (
                    <CircularProgress
                        style={{
                            position: "absolute",
                            top: "50%",
                            right: 0,
                            bottom: 0,
                            left: 0,
                            margin: "0 auto",
                        }}
                    />
                ) : (
                    filteredItems
                        .slice(start, end)
                        .map((customer) => (
                            <Fade key={customer.id} in timeout={1000}>
                                <Grid item xs={12} md={4} alignItems={"stretch"}>
                                    <CustomerSummaryCard customer={customer} />
                                </Grid>
                            </Fade>
                        ))
                )}
            </Grid>
            <Grid container item alignSelf="center" justifyContent={"flex-end"}>
                <Pagination
                    onChange={handlePageChange}
                    count={Math.ceil((filteredItems.length) / PAGE_SIZE)}
                />
            </Grid>
            <AddCustomerDialog open={open} onClose={handleDialogClose} />
        </Grid>
    );
};

export default CustomerManagement;
