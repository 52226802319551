import { Grid, Checkbox, Typography, Avatar, IconButton } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { useSnackbar } from "notistack";
import { useIntl } from "react-intl";
import {
  useGetUsersQuery,
  useUpdateUserMutation,
  useDeleteUserMutation,
} from "../../services/users";
import { useGetGroupsQuery } from "../../services/groups";
import { User } from "../../types/types";
import DeleteIcon from "@mui/icons-material/Delete";
import { useGrantAdminMutation } from "../../services/api";
import { useAppSelector } from "../../redux/hooks";

const UserManagement = () => {
  const {formatMessage: fMessage}= useIntl();
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const { data: users, isLoading } = useGetUsersQuery();
  const { data: groups } = useGetGroupsQuery();
  const [updateUser] = useUpdateUserMutation();
  const [deleteUser] = useDeleteUserMutation();
  const [grantAdmin] = useGrantAdminMutation();
  
  const user = useAppSelector(state => state.auth.user);

  const handleUpdateUserActiveFlag = (
    params: GridRenderCellParams<boolean, User>
  ) => {
    confirm({
      confirmationText: "Ok",
      cancellationText: "Cancel",
      description: fMessage(
        { id: "UserManagement.ChangeStatus.HelpText.ChangeStatusWarning" },
        {
          action: params.value ? "disable" : "enable",
          email: `${params.row.email}`,
        }
      ),
    })
      .then(() => {
        updateUser({
          id: params.row.id,
          request: { isActive: !params.row.isActive },
        })
          .unwrap()
          .then(() => {
            enqueueSnackbar("Updated user", { variant: "success" });
          })
          .catch((err) =>
            enqueueSnackbar("Failed to update user, please try again", {
              variant: "error",
            })
          );
      })
      .catch((err) => console.log("Failed to update user", err));
  };

  const handleGrantAdminRole = (
    params: GridRenderCellParams<boolean, User>
  ) => {
    confirm({
      confirmationText: "Ok",
      cancellationText: "Cancel",
      description: fMessage(
        { id: "UserManagement.GrantAdmin.HelpText.GrantAdminWarning" },
        {
          action: params.value ? "remove" : "add",
          email: `${params.row.email}`,
        }
      ),
    })
      .then(() => {
        grantAdmin(params.row.id)
          .unwrap()
          .then(() => {
            enqueueSnackbar("Updated user", { variant: "success" });
          })
          .catch((err) =>
            enqueueSnackbar("Failed to update user, please try again", {
              variant: "error",
            })
          );
      })
      .catch((err) => console.log("Failed to update user", err));
  };

  const handleDeleteUser = (params: GridRenderCellParams<void, User>) => {
    confirm({
      confirmationText: "Ok",
      cancellationText: "Cancel",
      description: fMessage(
        { id: "UserManagement.DeleteUser.DeleteUserWarning" },
        { email: `${params.row.email}` }
      ),
    })
      .then(() => {
        deleteUser(params.row.id)
          .unwrap()
          .then((deleted) => {
            enqueueSnackbar(
              fMessage(
                { id: "UserManagement.DeleteUser.Success" },
                { variant: "success" }
              )
            );
          })
          .catch(() => {
            enqueueSnackbar(
              fMessage({ id: "UserManagement.DeleteUser.Error" }),
              { variant: "error" }
            );
          });
      })
      .catch((err) => {});
  };

  const columns: GridColDef<User>[] = [
    {
      field: "firstName",
      headerName: fMessage(
        { id: "Common.Table.Header.FName" }
      ),
      renderCell: (params) => {
        return (
          <>
            <Avatar
              sx={{ width: 24, height: 24 }}
              alt={`${params.row?.firstName} ${params.row?.lastName}`}
              src={params.row.imageUrl ?? ""}
            >
              {params.row.lastName ? params.row.lastName[0] : "?"}
            </Avatar>
            <Typography sx={{ ml: 1 }} variant="body2">
              {params.value}
            </Typography>
          </>
        );
      },
      width: 150,
    },
    {
      field: "lastName",
      headerName: fMessage(
        { id: "Common.Table.Header.LName" }
      ),
      width: 150,
    },
    {
      field: "jobTitle",
      headerName: fMessage(
        { id: "Common.Table.Header.JobTitle" }
      ),
      width: 150,
    },
    {
      field: "email",
      headerName: fMessage(
        { id: "Common.Table.Header.Email" }
      ),
      width: 280,
    },
    {
      field: "groupId",
      headerName: fMessage(
        { id: "Common.Table.Header.Team" }
      ),
      width: 150,
      editable: true,
      valueGetter: (params) => {
        return (
          groups?.find((group) => group.id === params.value)?.name ??
          "Unassigned"
        );
      },
    },
    {
      field: "gender",
      headerName: "Gender",
      width: 110,
    },
    {
      field: "isActive",
      headerName: fMessage(
        { id: "Common.Table.Header.IsActive" }
      ),
      description: "Whether user is enabled or not",
      sortable: true,
      width: 120,
      editable: false,
      renderCell: (params) => {
        return (
          <Checkbox
            color="success"
            disabled={params.row.email === user?.email}
            onChange={() => handleUpdateUserActiveFlag(params)}
            checked={params.value}
          />
        );
      },
    },
    {
      field: "isAdmin",
      headerName: fMessage(
        { id: "Common.Table.Header.Admin" }
      ),
      disableExport: true,
      editable: false,
      renderCell: (params) => {
        return (
          <Checkbox
            disabled={params.row.email === user?.email}
            color="success"
            onChange={() => handleGrantAdminRole(params)}
            checked={params.value}
          />
        );
      },
    },
    {
      field: "action",
      headerName: fMessage(
        { id: "Common.Table.Header.DeleteUser" }
      ),
      disableExport: true,
      renderCell: (params) => {
        return (
          <IconButton disabled={params.row.email === user?.email} onClick={() => handleDeleteUser(params)}>
            <DeleteIcon />
          </IconButton>
        );
      },
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12} height={"90vh"}>
        <DataGrid
          columns={columns}
          rows={!users ? [] : users}
          pageSize={20}
          rowsPerPageOptions={[20]}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          components={{ Toolbar: GridToolbarQuickFilter }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        ></DataGrid>
      </Grid>
    </Grid>
  );
};

export default UserManagement;
