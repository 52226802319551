import { FileUploadResponse, FileUploadRequest } from "../types/types";
import { api } from "./api";
import { getEnv } from "../getEnv";

const basePath = `${getEnv().API_PATH_FILES}/files`;

const constructRequest = (req: FileUploadRequest) => {
  const formData = new FormData();
  formData.append("file", req.file);
  formData.append("asset", req.asset);
  formData.append("assetId", req.assetId);
  return formData;
};

const filesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    uploadFile: builder.mutation<FileUploadResponse, FileUploadRequest>({
      query: (req) => ({
        url: basePath,
        method: "POST",
        body: constructRequest(req),
      }),
      invalidatesTags: ["File"],
    }),
    deleteFile: builder.mutation<void, { id: string }>({
      query: (req) => ({
        url: `${basePath}/${req.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["File"],
    }),
  }),
  overrideExisting: false,
});

export const { useUploadFileMutation, useDeleteFileMutation } = filesApi;
