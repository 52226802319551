import React from "react";
import {ListItem, ListItemBaseProps, ListItemIcon, ListItemText, useTheme } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";


interface IStylizedMenuListItem extends ListItemBaseProps {
    icon: React.ReactNode,
    selected: boolean,
    primary: string,
    to: string,
    state: {
      pageId: String;
    }
}

/**
 * Sidebar menu item
 */
export const StylizedMenuListItem = (props: IStylizedMenuListItem) => {
    const { icon, primary, to, state, selected } = props;
    const theme = useTheme();
  
    const renderLink = React.useMemo(
      () =>
        React.forwardRef(function Link(itemProps, ref: React.ForwardedRef<HTMLAnchorElement>) {
          return <RouterLink to={to} state={state} ref={ref} {...itemProps} role={undefined} />;
        }),
      [to, state]
    );
  
    const styles = {
      color: selected
        ? theme.palette.icon?.secondary
        : theme.palette.icon?.primary,
      cursor: "pointer",
    };
  
    return (
      <li>
        <ListItem button component={renderLink}>
          {icon ? <ListItemIcon style={styles}>{icon}</ListItemIcon> : null}
          <ListItemText color={styles.color} primary={primary} />
        </ListItem>
      </li>
    );
  };