import { GroupResponse, GroupUpdateRequest } from "../types/types";
import { api } from "./api";
import { getEnv } from "../getEnv";

const basePath = `${getEnv().API_PATH_GROUPS}/groups`;

const groupApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getGroups: builder.query<GroupResponse[], string | void>({
      query: (customerId) => ({
        url: `${basePath}/all`,
        method: "GET",
        params: customerId ? { customerId } : undefined,
      }),
      providesTags: ["Group"],
    }),
    getGroup: builder.query<GroupResponse, { id: string; customerId?: string }>({
      query: ({ id, customerId }) => ({
        url: `${basePath}/${id}`,
        method: "GET",
        params: customerId ? { customerId } : undefined,
      }),
    }),
    createGroup: builder.mutation<GroupResponse, { request: GroupUpdateRequest; customerId?: string }>({
      query: ({ request, customerId }) => ({
        url: basePath,
        method: "POST",
        body: request,
        params: customerId ? { customerId } : undefined,
      }),
      invalidatesTags: ["Group"],
    }),
    updateGroup: builder.mutation<
        GroupResponse,
        { id: string; request: GroupUpdateRequest; customerId?: string }
    >({
      query: ({ id, request, customerId }) => ({
        url: `${basePath}/${id}`,
        method: "PUT",
        body: request,
        params: customerId ? { customerId } : undefined,
      }),
      invalidatesTags: ["Group"],
    }),
    deleteGroup: builder.mutation<GroupResponse, { id: string; customerId?: string }>({
      query: ({ id, customerId }) => ({
        url: `${basePath}/${id}`,
        method: "DELETE",
        params: customerId ? { customerId } : undefined,
      }),
      invalidatesTags: ["Group"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetGroupQuery,
  useGetGroupsQuery,
  useCreateGroupMutation,
  useUpdateGroupMutation,
  useDeleteGroupMutation,
} = groupApi;
