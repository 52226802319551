import { FastField, FieldProps, Formik } from "formik";
import { useIntl } from "react-intl";
import { TagResponse, TagUpdateRequest } from "../../../types/types";
import * as yup from "yup";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useUpdateTagMutation } from "../../../services/tags";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { SerializedError } from "@reduxjs/toolkit";

interface IEditTagDialog {
  tag: TagResponse;
  onClose: () => void;
  open: boolean;
}

const EditTagDialog = (props: IEditTagDialog) => {
  const { tag, onClose: handleClose, open } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [updateTag] = useUpdateTagMutation();

  const intl = useIntl();

  const handleUpdateTag = (values: TagUpdateRequest) => {
    updateTag({
      id: tag.id,
      request: { name: values.name?.trim(), description: values.description },
    })
      .unwrap()
      .then((res: TagResponse) => {
        enqueueSnackbar(
          intl.formatMessage({ id: "Category.UpdateCategory.Success" }),
          { variant: "success" }
        );
        handleClose();
      })
      .catch((err: FetchBaseQueryError | SerializedError) => {
        if ("data" in err && err.status === 409) {
          enqueueSnackbar(
            intl.formatMessage({
              id: "Category.CategoryForm.Error.CategoryExists",
            }),
            { variant: "error" }
          );
        } else {
          enqueueSnackbar(
            intl.formatMessage({
              id: "Category.CategoryForm.Error.ValidationErrors",
            }),
            { variant: "error" }
          );
        }
      });
  };

  return (
    <Formik
      initialValues={tag}
      validationSchema={yup.object().shape({
        name: yup
          .string()
          .max(32, (val) =>
            intl.formatMessage(
              { id: "Validation.MaxLengthNamed" },
              {
                name: intl.formatMessage({
                  id: "Common.Label.Name",
                }),
                x: val.max,
              }
            )
          )
          .required(intl.formatMessage({ id: "Validation.FieldRequired" })),
      })}
      onSubmit={handleUpdateTag}
    >
      {({ handleSubmit, errors }) => (
        <Dialog onClose={handleClose} open={open}>
          <DialogTitle>
            {intl.formatMessage({
              id: "Category.EditCategory.HelpText.Header",
            })}
          </DialogTitle>
          <DialogContent>
            <DialogContentText paddingBottom={2}>
              {intl.formatMessage({ id: "Category.CategoryForm.HelpText" })}
            </DialogContentText>
            <Grid container spacing={1} sx={{ mt: 0.5 }}>
              <Grid item xs={12}>
                <FastField name="name">
                  {({ field }: FieldProps) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="outlined"
                      label={intl.formatMessage({
                        id: "Common.Label.Name",
                      })}
                      required
                      inputRef={input => input && input.focus()}
                      error={!!errors.name}
                      helperText={errors.name}
                    />
                  )}
                </FastField>
              </Grid>
              <Grid item xs={12}>
                <FastField name="description">
                  {({ field }: FieldProps) => (
                    <TextField
                      {...field}
                      multiline
                      maxRows={5}
                      fullWidth
                      variant="outlined"
                      label={intl.formatMessage({
                        id: "Common.Label.Description",
                      })}
                      helperText={intl.formatMessage({
                        id: "Category.Common.HelpText.Description",
                      })}
                    />
                  )}
                </FastField>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={(event) => handleSubmit()}>
              {intl.formatMessage({
                id: "Category.EditCategory.HelpText.Button",
              })}
            </Button>
            <Button variant="outlined" color="error" onClick={handleClose}>
              {intl.formatMessage({ id: "Common.Button.Cancel" })}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};

export default EditTagDialog;
