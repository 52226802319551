import React, {useMemo, useState} from "react";
import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    IconButton,
    Typography,
    Tooltip,
    Chip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useIntl } from "react-intl";
import EditCustomerDialog from "./EditCustomerDialog";
import {CustomerDataResponse} from "../../types/types";

interface ICustomerSummaryCard {
    customer: CustomerDataResponse;
}

const CustomerSummaryCard: React.FC<ICustomerSummaryCard> = ({ customer }) => {
    const intl = useIntl();
    const [editDialogOpen, setEditDialogOpen] = useState(false);

    const handleEditClick = () => {
        setEditDialogOpen(true);
    };

    const handleEditDialogClose = () => {
        setEditDialogOpen(false);
    };

    const getSilentTime = useMemo(() => {
        if(customer.silentPeriodStartHour == null || customer.silentPeriodStartMinute== null || customer.silentPeriodEndHour== null || customer.silentPeriodEndMinute== null) {
            return 'Usatt'
        }

        return `${customer.silentPeriodStartHour}:${customer.silentPeriodStartMinute.toString().padStart(2, '0')} - ${customer.silentPeriodEndHour}:${customer.silentPeriodEndMinute.toString().padStart(2, '0')}`
    }, [customer])

    return (
        <Card variant="outlined" sx={{ height: "100%" }}>
            <CardHeader
                title={
                    <Tooltip title={customer.name} placement="top-start">
                        <Typography noWrap variant="h5">
                            {customer.name}
                        </Typography>
                    </Tooltip>
                }
                action={
                    <Tooltip title={intl.formatMessage({ id: "Common.Hints.Edit" })}>
                        <IconButton onClick={handleEditClick}>
                            <EditIcon color="primary" />
                        </IconButton>
                    </Tooltip>
                }
            />
            <Divider />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">
                            {intl.formatMessage({ id: "CustomerManagement.Common.ApprovedDomains" })}
                        </Typography>
                        <Typography variant="body2">{customer.approvedDomains}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">
                            {intl.formatMessage({ id: "CustomerManagement.Common.Status" })}
                        </Typography>
                        <Chip
                            label={customer.isActive ? "Active" : "Inactive"}
                            color={customer.isActive ? "success" : "error"}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">
                            {intl.formatMessage({ id: "CustomerManagement.Common.SilentPeriod" })}
                        </Typography>
                        <Typography variant="body2">
                            {getSilentTime}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
            <EditCustomerDialog
                open={editDialogOpen}
                onClose={handleEditDialogClose}
                customer={customer}
            />
        </Card>
    );
};

export default CustomerSummaryCard;
