import {FC} from 'react';
import * as React from "react";
import Brandingbar from "../../components/brandingbar/brandingbar";
import {Box} from '@mui/material';

interface PrivacyPolicyProps {
}

const PrivacyPolicy: FC<PrivacyPolicyProps> = (props) => {
    return (
        <section>
            <div
                style={{
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    // background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${"assets/images/cover_image.jpeg"})`,
                    height: "100vh", width: "100vw",
                    position: 'fixed'
                }}
            >
            </div>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>

                <Brandingbar/>


                <div style={{
                    display: "flex",
                    backgroundColor: 'white',
                    maxWidth: 'min(940px, 100%)',
                    width: '100%',
                    zIndex: 2,
                    marginTop: '3rem',
                    borderRadius: '10px',
                    padding: '0.875rem'
                }}>
                    <div>
                        <h1>Privacy Policy</h1>
                        <p>Last Updated: August 19, 2024</p>

                        <h2>Introduction</h2>
                        <p>
                            Welcome to Masterment AS. This Privacy Policy explains how Masterment AS ("we", "our", or "us")
                            collects, uses, and discloses information from users ("you") of our app and website.
                        </p>

                        <p>By using our app and/or website, you agree to the collection and use of information in accordance with this policy.</p>

                        <h2>Information We Collect</h2>

                        <h3>1. Information You Provide to Us</h3>
                        <ul>
                            <li>
                                <strong>Account Information:</strong> When you sign up for an account, we collect personal information such as your name, and email address.
                            </li>
                            <li>
                                <strong>Content:</strong> Any information you provide when using our services, such as profile information, messages, and other content.
                            </li>
                        </ul>

                        <h3>2. Information We Collect Automatically</h3>
                        <ul>
                            <li>
                                <strong>Device Information:</strong> We may collect information about the device you use to access our services, including the device type, operating system, and unique device identifiers.
                            </li>
                            <li>
                                <strong>Cookies and Tracking Technologies:</strong> We use cookies and similar tracking technologies to track the activity on our service and hold certain information.
                            </li>
                        </ul>

                        <h2>How We Use Your Information</h2>
                        <p>We use the information we collect in various ways, including to:</p>
                        <ul>
                            <li>Provide, operate, and maintain our app and website</li>
                            <li>Improve, personalize, and expand our services</li>
                            <li>Understand and analyze how you use our app and website</li>
                            <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the app, and for marketing and promotional purposes</li>
                            <li>Process your transactions and manage your orders</li>
                            <li>Find and prevent fraud</li>
                            <li>Comply with legal obligations</li>
                        </ul>

                        <h2>Sharing Your Information</h2>
                        <p>We may share your information with:</p>
                        <ul>
                            <li>Service providers that assist us in operating our app and website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential</li>
                            <li>Law enforcement or other governmental agencies as required by law</li>
                            <li>Other third parties with your consent to do so</li>
                        </ul>

                        <h2>Your Data Protection Rights</h2>
                        <p>You have the right to:</p>
                        <ul>
                            <li>Access the personal data we hold about you</li>
                            <li>Request that we correct any personal data that is inaccurate or incomplete</li>
                            <li>Request that we delete your personal data under certain conditions</li>
                            <li>Object to or restrict the processing of your personal data under certain conditions</li>
                            <li>Request the transfer of your personal data to another organization, or directly to you, under certain conditions</li>
                        </ul>
                        <p>
                            If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at our provided contact information.
                        </p>

                        <h2>Security of Your Information</h2>
                        <p>
                            We use commercially reasonable measures to protect the information we collect from you. However, please note that no method of transmission over the internet, or method of electronic storage, is 100% secure.
                        </p>

                        <h2>Changes to This Privacy Policy</h2>
                        <p>
                            We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
                        </p>

                        <h2>Contact Us</h2>
                        <p>If you have any questions about this Privacy Policy, please contact us:</p>
                        <ul>
                            <li>By email: support@masterment.no</li>
                            {/*<li>By visiting this page on our website: [Your Contact Page URL]</li>*/}
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PrivacyPolicy


