import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useIntl } from "react-intl";
import { User } from "../../../types/types";

interface IUserTable {
  members: User[];
}
export const UserTable = (props: IUserTable) => {
  const { members } = props;
  const { formatMessage: fMessage } = useIntl();

  return members.length > 0 ? (
    <TableContainer component={Paper} variant={"outlined"}>
      <Table sx={{ minWidth: 400 }} aria-label="team member table">
        <TableHead>
          <TableRow>
            <TableCell align="right">
              {fMessage({ id: "Common.Table.Header.Name" })}
            </TableCell>
            <TableCell align="right">
              {" "}
              {fMessage({ id: "Common.Table.Header.JobTitle" })}
            </TableCell>
            <TableCell align="right">
              {" "}
              {fMessage({ id: "Common.Table.Header.Email" })}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {members.map((member: User) => (
            <TableRow
              key={member.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="right">{`${member.firstName} ${member.lastName}`}</TableCell>
              <TableCell align="right">{member.jobTitle}</TableCell>
              <TableCell align="right">{member.email}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <Typography variant="body2">No users are assigned to this team</Typography>
  );
};
