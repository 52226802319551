const env = (window as any).env;
const defaultEnv = process.env;

export const getEnv = () => ({
  API_PATH_AUTH: env?.API_PATH_AUTH
    ? env.API_PATH_AUTH
    : (defaultEnv.REACT_APP_API_PATH_AUTH as string),
  API_PATH_ACTIVITIES: env?.API_PATH_ACTIVITIES
    ? env.API_PATH_ACTIVITIES
    : (defaultEnv.REACT_APP_API_PATH_ACTIVITIES as string),
  API_PATH_GROUPS: env?.API_PATH_GROUPS
    ? env.API_PATH_GROUPS
    : (defaultEnv.REACT_APP_API_PATH_GROUPS as string),
  API_PATH_TAGS: env?.API_PATH_TAGS
    ? env.API_PATH_TAGS
    : (defaultEnv.REACT_APP_API_PATH_TAGS as string),
  API_PATH_USERS: env?.API_PATH_USERS
    ? env.API_PATH_USERS
    : (defaultEnv.REACT_APP_API_PATH_USERS as string),
  API_PATH_FILES: env?.API_PATH_FILES
    ? env.API_PATH_FILES
    : (defaultEnv.REACT_APP_API_PATH_FILES as string),
});
