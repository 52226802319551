import { useState } from "react";

function usePagination(pageSize: number) {
  const [start, setStart] = useState<number>(0);
  const [end, setEnd] = useState<number>(pageSize);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setStart(pageSize * page - pageSize);
    setEnd(pageSize * page);
  };

  const resetState = () => {
    setStart(pageSize * 1 - pageSize);
    setEnd(pageSize * 1);
  }

  return { start, end, handlePageChange, resetState };
}

export default usePagination;
