import { Button, Fade, Grid, Pagination } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { GoalCard, AddGoalDialog, EditGoalDialog } from "./components";
import { SearchInput } from "../../components/common";
import { useIntl } from "react-intl";
import { useDeleteGoalMutation, useGetGoalsQuery } from "../../services/goals";
import { Loadable } from "../../components/loadable/Loadable";
import usePagination from "../../hooks/usePagination";
import useToggle from "../../hooks/useToggle";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import { useGetActivitiesQuery } from "../../services/activities";
import { GoalResponse } from "../../types/types";

export interface IGoalsProps {}

const PAGE_SIZE = 6;

const Goals = (props: IGoalsProps) => {
  const [activeGoal, setActiveGoal] = useState<string>("");

  const { data: goals, isLoading, refetch } = useGetGoalsQuery();
  const { data: activities, isLoading: isLoadingActivities } =
    useGetActivitiesQuery();
  const [isGoalsFormOpen, setIsGoalsFormOpen] = useToggle(false);
  const [isEditFormOpen, setIsEditFormOpen] = useToggle(false);

  const intl = useIntl();
  const { start, end, handlePageChange, resetState} = usePagination(PAGE_SIZE);
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const [deleteGoal] = useDeleteGoalMutation();
  const [filteredItems, setFilteredItems] = useState<GoalResponse[]>([]);

  const handleSearch = (
    e: React.SyntheticEvent<Element, Event>,
    value: string
  ) => {
    const search = value.toLowerCase();
    if (goals) {
      setFilteredItems(goals.filter(
        (val) =>
          search.length === 0 ||
          val.name.toLocaleLowerCase().indexOf(search) > -1 ||
          val.description.toLowerCase().indexOf(search) > -1
      ))
    }
    resetState();
  };

  useEffect(() => {
    if (goals) {
      setFilteredItems(goals);
    }
  }, [goals])

  const onDeleteCallback = (id: string, name: string) => {
    confirm({
      title:
        intl.formatMessage({
          id: "Goal.DeleteGoal.HelpText.DeleteGoal",
        }) + "?",
      description: intl.formatMessage(
        {
          id: "Goal.DeleteGoal.HelpText.DeleteGoalWarning",
        },
        { name }
      ),
      confirmationText: intl.formatMessage({
        id: "Goal.DeleteGoal.HelpText.DeleteGoal",
      }),
    }).then(() => {
      deleteGoal({ id })
        .unwrap()
        .then(() => {
          enqueueSnackbar(
            intl.formatMessage({ id: "Goal.DeleteGoal.Success" }),
            { variant: "success" }
          );
        })
        .catch((err) => {
          enqueueSnackbar(intl.formatMessage({ id: "Goal.DeleteGoal.Error" }), {
            variant: "error",
          });
        });
    });
  };

  const currentGoal = useMemo(
    () => goals?.find((goal) => goal.id === activeGoal),
    [goals, activeGoal]
  );

  return (
    <Grid
      sx={{ pt: 2 }}
      container
      rowGap={2}
      spacing={3}
      justifyContent="flex-start"
    >
      <Grid item xs={12} md={8}>
        <SearchInput
          onChangeCallback={handleSearch}
          id="activity-filter"
          options={
            goals
              ? goals.map((goal) => ({
                  name: goal.name,
                  id: goal.id,
                }))
              : []
          }
          placeholderTextId="Goal.Common.HelpText.Search"
        />
      </Grid>
      <Grid
        container
        item
        xs={12}
        md={4}
        alignSelf="center"
        justifyContent="flex-end"
      >
        <Button onClick={() => setIsGoalsFormOpen(true)} variant="outlined">
          {intl.formatMessage({
            id: "Goal.Common.HelpText.CreateGoal",
          })}
        </Button>
      </Grid>
      <Grid container item md={12} spacing={2}>
        <Loadable isLoading={isLoading || isLoadingActivities}>
          {filteredItems
            .slice(start, end)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((goal) => (
              <Fade key={goal.id} in timeout={1000}>
                <Grid item xs={12} md={4}>
                  <GoalCard
                    goal={goal}
                    activities={activities ?? []}
                    onDeleteCallback={onDeleteCallback}
                    onEditCallback={(goalId: string) => {
                      setActiveGoal(goalId);
                      setIsEditFormOpen(true);
                    }}
                    key={goal.id}
                  />
                </Grid>
              </Fade>
            ))}
        </Loadable>
      </Grid>
      <Grid container item alignSelf="center" justifyContent={"flex-end"}>
        <Pagination
          onChange={handlePageChange}
          count={Math.ceil((filteredItems.length) / PAGE_SIZE)}
        />
      </Grid>
      <AddGoalDialog
        isOpen={isGoalsFormOpen}
        onCloseCallback={() => setIsGoalsFormOpen(false)}
        activities={activities ?? []}
        onGoalCreatedCallback={(goalId) => {
          setActiveGoal(goalId);
          refetch();
        }}
      />
      {currentGoal && (
        <EditGoalDialog
          isOpen={isEditFormOpen}
          onCloseCallback={() => setIsEditFormOpen(false)}
          onGoalEditCallback={() => {
            setActiveGoal("");
            refetch();
          }}
          goal={currentGoal}
          activities={activities ?? []}
          key={currentGoal.id}
        />
      )}
    </Grid>
  );
};

export default Goals;
