import React from "react";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Switch,
    FormControlLabel,
    Chip,
    Box,
} from "@mui/material";
import { FastField, FieldProps, Formik, FormikHelpers } from "formik";
import * as yup from "yup";
import {CustomerDataResponse, CustomerUpdateRequest} from "../../types/types";
import {useUpdateCustomerDataMutation} from "../../services/customers";

interface IEditCustomerDialog {
    onClose: () => void;
    open: boolean;
    customer: CustomerDataResponse;
}

type FormValues = Omit<Required<CustomerUpdateRequest>, 'approvedDomains'> & {
    approvedDomains: string[];
};

const EditCustomerDialog: React.FC<IEditCustomerDialog> = ({ onClose, open, customer }) => {
    const intl = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const [updateCustomer] = useUpdateCustomerDataMutation();

    const handleUpdateCustomer = (
        values: FormValues,
        helpers: FormikHelpers<FormValues>
    ) => {
        const customerData: CustomerUpdateRequest = {
            ...values,
            approvedDomains: values.approvedDomains.join(','),
        };

        updateCustomer({ id: customer.id, request: customerData })
            .unwrap()
            .then(() => {
                enqueueSnackbar(
                    intl.formatMessage({ id: "CustomerManagement.EditCustomer.Success" }),
                    { variant: "success" }
                );
                onClose();
            })
            .catch(() => {
                enqueueSnackbar(
                    intl.formatMessage({ id: "CustomerManagement.EditCustomer.Error" }),
                    { variant: "error" }
                );
            });
    };

    const initialValues: FormValues = {
        name: customer.name,
        approvedDomains: customer.approvedDomains.split(',').map(domain => domain.trim()),
        isActive: customer.isActive,
        silentPeriodStartHour: customer.silentPeriodStartHour,
        silentPeriodStartMinute: customer.silentPeriodStartMinute,
        silentPeriodEndHour: customer.silentPeriodEndHour,
        silentPeriodEndMinute: customer.silentPeriodEndMinute,
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={yup.object().shape({
                name: yup.string().required(intl.formatMessage({ id: "Validation.FieldRequired" })),
                approvedDomains: yup.array().of(yup.string()).min(1, intl.formatMessage({ id: "Validation.MinOneApprovedDomain" })),
                silentPeriodStartHour: yup.number().min(0).max(23),
                silentPeriodStartMinute: yup.number().min(0).max(59),
                silentPeriodEndHour: yup.number().min(0).max(23),
                silentPeriodEndMinute: yup.number().min(0).max(59),
            })}
            onSubmit={handleUpdateCustomer}
        >
            {({ values, setFieldValue, errors, handleSubmit }) => (
                <Dialog onClose={onClose} open={open}>
                    <DialogTitle>
                        {intl.formatMessage({
                            id: "CustomerManagement.EditCustomer.HelpText.EditCustomer",
                        })}
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FastField name="name">
                                    {({ field }: FieldProps) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            label={intl.formatMessage({
                                                id: "CustomerManagement.Common.Name",
                                            })}
                                            error={!!errors.name}
                                            helperText={errors.name}
                                        />
                                    )}
                                </FastField>
                            </Grid>
                            <Grid item xs={12}>
                                <FastField name="approvedDomains">
                                    {({ field }: FieldProps) => (
                                        <Box>
                                            <TextField
                                                fullWidth
                                                label={intl.formatMessage({
                                                    id: "CustomerManagement.Common.ApprovedDomains",
                                                })}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter' && (e.target as HTMLInputElement).value) {
                                                        e.preventDefault();
                                                        const newDomain = (e.target as HTMLInputElement).value.trim();
                                                        if (newDomain && !values.approvedDomains.includes(newDomain)) {
                                                            setFieldValue('approvedDomains', [...values.approvedDomains, newDomain]);
                                                            (e.target as HTMLInputElement).value = '';
                                                        }
                                                    }
                                                }}
                                                error={!!errors.approvedDomains}
                                                helperText={errors.approvedDomains as string}
                                            />
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 1 }}>
                                                {values.approvedDomains.map((domain, index) => (
                                                    <Chip
                                                        key={index}
                                                        label={domain}
                                                        onDelete={() => {
                                                            const newDomains = values.approvedDomains.filter((_, i) => i !== index);
                                                            setFieldValue('approvedDomains', newDomains);
                                                        }}
                                                    />
                                                ))}
                                            </Box>
                                        </Box>
                                    )}
                                </FastField>
                            </Grid>
                            <Grid item xs={12}>
                                <FastField name="isActive">
                                    {({ field }: FieldProps) => (
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => setFieldValue("isActive", e.target.checked)}
                                                />
                                            }
                                            label={intl.formatMessage({
                                                id: "CustomerManagement.Common.IsActive",
                                            })}
                                        />
                                    )}
                                </FastField>
                            </Grid>
                            <Grid item xs={6}>
                                <FastField name="silentPeriodStartHour">
                                    {({ field }: FieldProps) => (
                                        <TextField
                                            {...field}
                                            type="number"
                                            fullWidth
                                            label={intl.formatMessage({
                                                id: "CustomerManagement.Common.SilentPeriodStartHour",
                                            })}
                                            error={!!errors.silentPeriodStartHour}
                                            helperText={errors.silentPeriodStartHour}
                                        />
                                    )}
                                </FastField>
                            </Grid>
                            <Grid item xs={6}>
                                <FastField name="silentPeriodStartMinute">
                                    {({ field }: FieldProps) => (
                                        <TextField
                                            {...field}
                                            type="number"
                                            fullWidth
                                            label={intl.formatMessage({
                                                id: "CustomerManagement.Common.SilentPeriodStartMinute",
                                            })}
                                            error={!!errors.silentPeriodStartMinute}
                                            helperText={errors.silentPeriodStartMinute}
                                        />
                                    )}
                                </FastField>
                            </Grid>
                            <Grid item xs={6}>
                                <FastField name="silentPeriodEndHour">
                                    {({ field }: FieldProps) => (
                                        <TextField
                                            {...field}
                                            type="number"
                                            fullWidth
                                            label={intl.formatMessage({
                                                id: "CustomerManagement.Common.SilentPeriodEndHour",
                                            })}
                                            error={!!errors.silentPeriodEndHour}
                                            helperText={errors.silentPeriodEndHour}
                                        />
                                    )}
                                </FastField>
                            </Grid>
                            <Grid item xs={6}>
                                <FastField name="silentPeriodEndMinute">
                                    {({ field }: FieldProps) => (
                                        <TextField
                                            {...field}
                                            type="number"
                                            fullWidth
                                            label={intl.formatMessage({
                                                id: "CustomerManagement.Common.SilentPeriodEndMinute",
                                            })}
                                            error={!!errors.silentPeriodEndMinute}
                                            helperText={errors.silentPeriodEndMinute}
                                        />
                                    )}
                                </FastField>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="primary">
                            {intl.formatMessage({ id: "Common.Button.Cancel" })}
                        </Button>
                        <Button onClick={() => handleSubmit()} color="primary" variant="contained">
                            {intl.formatMessage({ id: "CustomerManagement.EditCustomer.Button.UpdateCustomer" })}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Formik>
    );
};

export default EditCustomerDialog;
