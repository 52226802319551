import { CircularProgress, Grid } from "@mui/material";
import React, { FC } from "react";

interface ILoadable {
  isLoading: boolean;
  children?: React.ReactNode;
}

export const Loadable: FC<ILoadable> = ({ isLoading, children }) => {
  if (isLoading && !children) {
    return (
      <Grid
        item
        style={{
          width: "100%",
          height: "100%",
          zIndex: 99,
          position: "absolute",
          top: 0,
          left: 0,
          backgroundColor: "rgba(255,255,255,0.5)",
        }}
      >
        <CircularProgress
          style={{
            position: "absolute",
            top: "50%",
            right: 0,
            bottom: 0,
            left: 0,
            margin: "0 auto",
          }}
        />
      </Grid>
    );
  }

  if (isLoading)
    return (
      <CircularProgress
        style={{
          position: "absolute",
          top: "50%",
          right: 0,
          bottom: 0,
          left: 0,
          margin: "0 auto",
        }}
      />
    );

  return <>{children && children}</>;
};
