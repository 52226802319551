import { useState } from "react";

function useToggle(initial: boolean = false) {
  const [isToggled, setIsToggled] = useState<boolean>(initial);

  const toggle = (value?: boolean) => {
    setIsToggled((prev) => (value ? value : !prev));
  };

  return [isToggled, toggle] as const;
}

export default useToggle;
