import React, { FC } from "react";
import { Grid, MenuItem, TextField } from "@mui/material";
import { FastField, FieldProps } from "formik";
import { useIntl } from "react-intl";
import { useState } from "react";

interface ITimespanInput {
  name: string;
  label: string;
  onChangeOptionValue: (val: number) => void;
  defaultTimeperiod?: IOptionTypes;
}

type IOptionTypes = "Days" | "Months" | "Weeks";

export const TimespanInput: FC<ITimespanInput> = ({
  name,
  label,
  onChangeOptionValue,
  defaultTimeperiod,
}) => {
  const [selectedTimeperiod, setSelectedTimeperiod] = useState<IOptionTypes>(
    defaultTimeperiod ?? "Days"
  );
  const intl = useIntl();
  const options = [
    { label: "Days", value: "Days" },
    { label: "Weeks", value: "Weeks" },
    { label: "Months", value: "Months" },
  ];

  const dayOptions = {
    Days: [
      { label: "1 Day", value: 1 },
      { label: "2 Days", value: 2 },
      { label: "3 Days", value: 3 },
      { label: "4 Days", value: 4 },
      { label: "5 Days", value: 5 },
    ],
    Weeks: [
      { label: "1 Week", value: 7 },
      { label: "2 Weeks", value: 14 },
      { label: "3 Weeks", value: 21 },
    ],
    Months: [
      { label: "1 Month", value: 30 },
      { label: "2 Months", value: 60 },
      { label: "3 Months", value: 90 },
    ],
  };

  const handleOptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { value } = event.target;
    setSelectedTimeperiod(value as IOptionTypes);
    switch (value) {
      case "Days":
        onChangeOptionValue(1);
        break;
      case "Weeks":
        onChangeOptionValue(7);
        break;
      case "Months":
        onChangeOptionValue(30);
        break;
    }
  };

  return (
    <Grid item xs={12} display="flex">
      <FastField name={name}>
        {({ field, meta}: FieldProps) => (
          <>
            <TextField
              select
              fullWidth
              variant="outlined"
              label={intl.formatMessage({
                id: "Activity.ActivityForm.Interval",
              })}
              error={meta.touched && !!meta.error}
              helperText={meta.error}
              onChange={handleOptionChange}
              value={selectedTimeperiod}
            >
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              {...field}
              select
              fullWidth
              variant="outlined"
              label={intl.formatMessage({
                id: label,
              })}
              error={meta.touched && !!meta.error}
              helperText={meta.error}
            >
              {dayOptions[selectedTimeperiod].map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </>
        )}
      </FastField>
    </Grid>
  );
};
