import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
  Tooltip,
  Stack,
  Chip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { useConfirm } from "material-ui-confirm";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useDeleteGroupMutation } from "../../../services/groups";
import { useSnackbar } from "notistack";
import { GroupResponse } from "../../../types/types";
import { UserTable } from "./UserTable";
import EditTeamDialog from "./EditTeamDialog";
import ClampedTypography from "../../../components/common/ClampedTypography";
import TeamDetailDialog from "./TeamDetailDialog";

interface ITeamSummaryCard {
  group: GroupResponse;
  customerId?: string | null;
}
const TeamSummaryCard = (props: ITeamSummaryCard) => {
  const { group, customerId } = props;
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const [deleteGroup] = useDeleteGroupMutation();



  const [open, setOpen] = useState<boolean>(false);
  const [manageTeamOpen, setManageTeamOpen] = useState<boolean>(false);
  const handleCloseManagementForm = () => setManageTeamOpen(false);

  // show/hide fullscreen view of card (in modal)
  const [showDetails, setDetailsOpen] = useState<boolean>(false);
  const handleOpenDetails = () => {
    setDetailsOpen(true);
  };
  const handleCloseDetails = () => {
    setDetailsOpen(false);
  };

  const handleDeleteTeam = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (group.groupUsers.length > 0) {
      confirm({
        title: intl.formatMessage({
          id: "TeamManagement.DeleteTeam.CannotDeleteTeamHeader",
        }),
        description: intl.formatMessage({
          id: "TeamManagement.DeleteTeam.CannotDeleteTeam",
        }),
        cancellationButtonProps: { sx: { visibility: "hidden" } },
      });
      return;
    }

    confirm({
      title:
          intl.formatMessage({
            id: "TeamManagement.DeleteTeam.HelpText.DeleteTeam",
          }) + "?",
      description: intl.formatMessage(
          { id: "TeamManagement.DeleteTeam.HelpText.DeleteTeamWarning" },
          { name: group.name }
      ),
      confirmationText: intl.formatMessage({
        id: "TeamManagement.DeleteTeam.HelpText.DeleteTeam",
      }),
    }).then(() => {
      deleteGroup({ id: group.id, customerId: customerId ?? undefined })
          .unwrap()
          .then((deleted) => {
            enqueueSnackbar(
                intl.formatMessage({ id: "TeamManagement.DeleteTeam.Success" }),
                { variant: "success" }
            );
          })
          .catch((err) => {
            enqueueSnackbar(
                intl.formatMessage({ id: "TeamManagement.DeleteTeam.Error" }),
                { variant: "error" }
            );
          });
    });
  };
  return (
      <Card variant="outlined" sx={{ height: "100%" }}>
        <CardHeader
            title={
              <Stack direction="row" spacing={2}>
                <Tooltip title={group.name} placement="top-start">
                  <Typography noWrap variant="h5">
                    {group.name}
                  </Typography>
                </Tooltip>
                {group.isPrivate && (
                    <Chip
                        variant="outlined"
                        label={intl.formatMessage({
                          id: "TeamManagement.Common.PrivateTeam",
                        })}
                        size="small"
                        sx={{
                          alignSelf: "center",
                          "& .MuiChip-labelSmall": {
                            fontSize: 10,
                          },
                        }}
                        color="warning"
                    />
                )}
              </Stack>
            }
            sx={{
              display: "flex",
              overflow: "hidden",
              "& .MuiCardHeader-content": {
                overflow: "hidden",
              },
            }}
            subheader={
              <Button
                  style={{ paddingLeft: 0 }}
                  variant="text"
                  onClick={() => setOpen(true)}
              >
                {`${group.groupUsers.length} members`}
              </Button>
            }
            action={
              <>
                <Tooltip
                    title={intl.formatMessage({
                      id: "Common.Hints.Delete",
                    })}
                >
                  <IconButton onClick={handleDeleteTeam}>
                    <DeleteIcon color={"primary"} />
                  </IconButton>
                </Tooltip>
                <Tooltip
                    title={intl.formatMessage({
                      id: "Common.Hints.Edit",
                    })}
                >
                  <IconButton onClick={() => setManageTeamOpen(true)}>
                    <ManageAccountsIcon color="primary" />
                  </IconButton>
                </Tooltip>
              </>
            }
        />
        <Divider />
        <CardContent>
          <Grid container justifyContent={"space-between"}>
            <Grid item xs={12}>
              <ClampedTypography
                  text={group.description}
                  onClick={handleOpenDetails}
                  moreText="More Info"
                  lessText="Less Info"
                  lines={3}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogTitle>
            {intl.formatMessage({ id: "TeamManagement.Common.Users" })}
          </DialogTitle>
          <DialogContent>
            <UserTable members={group.groupUsers} />
          </DialogContent>
        </Dialog>
        <EditTeamDialog
            group={group}
            open={manageTeamOpen}
            onClose={handleCloseManagementForm}
            customerId={customerId}
        />
        <TeamDetailDialog
            open={showDetails}
            onClose={handleCloseDetails}
            group={group}
            customerId={customerId}
        />
      </Card>
  );
};

export default TeamSummaryCard;
