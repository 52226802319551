import { useSnackbar } from "notistack";
import { useIntl } from "react-intl";
import {
  TagResponse,
  ActivityResponse,
  ActivityUpdateRequest,
  GoalResponse,
} from "../../../types/types";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { SerializedError } from "@reduxjs/toolkit";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Formik, FormikHelpers } from "formik";
import { FC } from "react";
import {
  useGetActivityQuery,
  useUpdateActivityMutation,
} from "../../../services/activities";
import { TimespanInput } from "./Inputs";
import { UploadFileField } from "../../../components/common";
import {
  FormTextInput,
  MultiSelectFormInput,
} from "../../../components/common";
import { Loadable } from "../../../components/loadable/Loadable";
import * as yup from "yup";
import { useUploadFileMutation } from "../../../services/files";

interface IEditActivityDialog {
  onCloseCallback: () => void;
  onActivityEditCallback: () => void;
  isOpen: boolean;
  tags: Array<TagResponse>;
  activityId: string;
}

interface IEditForm extends ActivityUpdateRequest {
  image: File;
}

export const EditActivityDialog: FC<IEditActivityDialog> = ({
  onCloseCallback,
  isOpen,
  tags,
  onActivityEditCallback,
  activityId
}) => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [updateActivity, { isLoading }] = useUpdateActivityMutation();
  const [uploadFile, rest] = useUploadFileMutation();
  const { data: activity, isLoading: isActivityLoading, error} = useGetActivityQuery({
    id:  activityId,
  });

  if (isActivityLoading || !activity) {
    return <CircularProgress />;
  }

  const defaultTimePeriod = () => {
    if (activity.timeSpanDays < 6) return "Days";
    if (activity.timeSpanDays < 22) return "Weeks";
    return "Months";
  };

  const handleUpdateActivity = (
    values: IEditForm,
    helpers: FormikHelpers<IEditForm>
  ) => {
    const requests: Array<Promise<any>> = [];
    if (values.image) {
      const uploadFileRequest = uploadFile({
        file: values.image,
        asset: "ActivityImage",
        assetId: activity.id,
      })
        .then(() => {
          enqueueSnackbar(
            intl.formatMessage({ id: "Common.File.FileUpload.Success" }),
            { variant: "success" }
          );
        })
        .catch((err: FetchBaseQueryError | SerializedError) => {
          enqueueSnackbar(
            intl.formatMessage({ id: "Activity.EditActivity.Error" }),
            { variant: "error" }
          );
        });

      requests.push(uploadFileRequest);
    }

    const updateActivityRequest = updateActivity({
      id: activity.id,
      request: values,
    })
      .unwrap()
      .then((res: ActivityResponse) => {
        enqueueSnackbar(
          intl.formatMessage({ id: "Activity.EditActivity.Success" }),
          { variant: "success" }
        );
      })
      .catch((err: FetchBaseQueryError | SerializedError) => {
        if ("data" in err && err.status === 400) {
          enqueueSnackbar(
            intl.formatMessage({
              id: "Activity.ActivityForm.Error.ValidationErrors",
            }),
            { variant: "error" }
          );
        } else if ("data" in err && err.status === 409) {
          enqueueSnackbar(
            intl.formatMessage({
              id: "Activity.ActivityForm.Error.ActivityExists",
            }),
            { variant: "error" }
          );
        } else {
          enqueueSnackbar(
            intl.formatMessage({ id: "Activity.EditActivity.Error" }),
            { variant: "error" }
          );
        }
      });

    requests.push(updateActivityRequest);

    Promise.allSettled(requests).finally(() => {
      const { resetForm } = helpers;
      onActivityEditCallback();
      resetForm();
    });
  };

  return (
    <Formik
      initialValues={{
        name: activity.name,
        description: activity.description,
        selectedTagIds: activity.tags.map((tag) => tag.id),
        timeSpanDays: activity.timeSpanDays,
        image: undefined as unknown as File,
      }}
      onSubmit={handleUpdateActivity}
      validationSchema={yup.object().shape({
        name: yup
          .string()
          .min(1, (val) =>
            intl.formatMessage(
              { id: "Validation.MinLengthNamed" },
              {
                name: intl.formatMessage({
                  id: "Activity.ActivityForm.ActivityName",
                }),
                x: val.min,
              }
            )
          )
          .max(32, (val) =>
            intl.formatMessage(
              { id: "Validation.MaxLengthNamed" },
              {
                name: intl.formatMessage({
                  id: "Activity.ActivityForm.ActivityName",
                }),
                x: val.max,
              }
            )
          )
          .required(intl.formatMessage({ id: "Validation.FieldRequired" })),
        description: yup
          .string()
          .min(1, (val) =>
            intl.formatMessage(
              { id: "Validation.MinLengthNamed" },
              {
                name: intl.formatMessage({
                  id: "Activity.ActivityForm.Description",
                }),
                x: val.min,
              }
            )
          )
          .required(intl.formatMessage({ id: "Validation.FieldRequired" })),
        selectedTagIds: yup
          .array()
          .min(1, (val) =>
            intl.formatMessage(
              { id: "Validation.MinOneSelected" },
              {
                name: intl.formatMessage({
                  id: "Common.Label.Category",
                }).toLowerCase(),
              }
            )
          )
          .required(intl.formatMessage({ id: "Validation.FieldRequired" })),
      })}
    >
      {({ handleSubmit, errors, setFieldValue, resetForm }) => (
        <Dialog onClose={onCloseCallback} open={isOpen}>
          <DialogTitle>
            {intl.formatMessage({
              id: "Activity.EditActivity.HelpText.Header",
            })}
          </DialogTitle>
          <DialogContent>
            <DialogContentText paddingBottom={2}>
              {intl.formatMessage({ id: "Activity.ActivityForm.HelpText" })}
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid container item>
                <Grid container item xs={8} alignSelf={"center"}>
                  <FormTextInput
                    name="name"
                    label="Activity.ActivityForm.ActivityName"
                    required
                    inputRef={input => input && input.focus()}
                  />
                </Grid>
                <Grid container item xs={4} justifyContent="center">
                  <UploadFileField
                    name={"image"}
                    src={activity.imageUrl}
                    size={"100px"}
                  />
                </Grid>
              </Grid>
              <FormTextInput
                name="description"
                label="Activity.ActivityForm.Description"
                required
                multiline
                maxRows={5}
              />
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel shrink id="associated-goals-label">
                    Goals
                  </InputLabel>
                  <Select
                    value={activity.goals}
                    displayEmpty
                    multiple
                    fullWidth
                    renderValue={(
                      value: Array<Pick<GoalResponse, "id" | "name">>
                    ) => {
                      return value.length > 0
                        ? value.map((val) => val.name).join(", ")
                        : intl.formatMessage({
                            id: "Activity.EditActivity.HelpText.NoGoals",
                          });
                    }}
                    label={intl.formatMessage({
                      id: "Activity.ActivityForm.Goals",
                    })}
                    notched
                    labelId="associated-goals-label"
                  >
                    {activity.goals.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <MultiSelectFormInput
                name="selectedTagIds"
                label="Activity.ActivityForm.Categories"
                required
                options={tags.map((tag) => ({
                  label: tag.name,
                  value: tag.id,
                }))}
              />
              <TimespanInput
                name="timeSpanDays"
                label="Activity.ActivityForm.Timespan"
                onChangeOptionValue={(val: number) =>
                  setFieldValue("timeSpanDays", val)
                }
                defaultTimeperiod={defaultTimePeriod()}
              />
            </Grid>
            <Loadable isLoading={isLoading || rest.isLoading} />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={() => handleSubmit()}>
              {intl.formatMessage({
                id: "Activity.EditActivity.HelpText.Button",
              })}
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={() => {
                onCloseCallback();
                resetForm();
              }}
            >
              {intl.formatMessage({ id: "Common.Button.Cancel" })}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};
