import { Button, Fade, Grid, Pagination } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import {
  ActivityCard,
  AddActivityDialog,
  AddFileDialog,
  EditActivityDialog,
} from "./components";
import { SearchInput } from "../../components/common";
import { useIntl } from "react-intl";
import {
  useDeleteActivityMutation,
  useGetActivitiesQuery,
} from "../../services/activities";
import { useGetTagsQuery } from "../../services/tags";
import { Loadable } from "../../components/loadable/Loadable";
import usePagination from "../../hooks/usePagination";
import useToggle from "../../hooks/useToggle";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import { ActivityResponse } from "../../types/types";

export interface IActivitiesProps {}

const PAGE_SIZE = 6;

const Activities = (props: IActivitiesProps) => {
  const [activeActivity, setActiveActivity] = useState({
    id: "",
    pictureUrl: "",
  });

  const { data: activities, isLoading, refetch } = useGetActivitiesQuery();
  const { data: tags, isLoading: isLoadingTags } = useGetTagsQuery();
  const [isActivityFormOpen, setIsActivityFormOpen] = useToggle(false);
  const [isFileFormOpen, setIsFileFormOpen] = useToggle(false);
  const [isEditFormOpen, setIsEditFormOpen] = useToggle(false);

  const { formatMessage: fMessage } = useIntl();
  const { start, end, handlePageChange, resetState} = usePagination(PAGE_SIZE);
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const [deleteActivity] = useDeleteActivityMutation();

  const [filteredItems, setFilteredItems] = useState<ActivityResponse[]>([]);

  const handleSearch = (
    e: React.SyntheticEvent<Element, Event>,
    value: string
  ) => {
    const search = value.toLowerCase();

    setFilteredItems(
      activities?.filter(
        (val) =>
          search.length === 0 ||
          val.name.toLocaleLowerCase().indexOf(search) > -1 ||
          val.description.toLowerCase().indexOf(search) > -1
      ) ?? []
    );
    resetState();
  };

  const onDeleteCallback = (id: string, name: string) => {
    confirm({
      title:
        fMessage({
          id: "Activity.DeleteActivity.HelpText.DeleteActivity",
        }) + "?",
      description: fMessage(
        {
          id: "Activity.DeleteActivity.HelpText.DeleteActivityWarning",
        },
        { name }
      ),
      confirmationText: fMessage({
        id: "Activity.DeleteActivity.HelpText.DeleteActivity",
      }),
    }).then(() => {
      deleteActivity({ id })
        .unwrap()
        .then(() => {
          enqueueSnackbar(fMessage({ id: "Activity.DeleteActivity.Success" }), {
            variant: "success",
          });
        })
        .catch((err) => {
          enqueueSnackbar(fMessage({ id: "Activity.DeleteActivity.Error" }), {
            variant: "error",
          });
        });
    });
  };

  useEffect(() => {
    if (activities) {
      setFilteredItems(activities);
    }
  }, [activities]);

  const currentActivity = useMemo(
    () => activities?.find((act) => act.id === activeActivity.id),
    [activities, activeActivity.id]
  );

  return (
    <Grid
      sx={{ pt: 2 }}
      container
      rowGap={2}
      spacing={3}
      justifyContent="flex-start"
    >
      <Grid item xs={12} md={8}>
        <SearchInput
          onChangeCallback={handleSearch}
          id="activity-filter"
          options={
            activities
              ? activities.map((item) => ({ name: item.name, id: item.id }))
              : []
          }
          placeholderTextId="Activity.Common.HelpText.Search"
        />
      </Grid>
      <Grid
        container
        item
        xs={12}
        md={4}
        alignSelf="center"
        justifyContent="flex-end"
      >
        <Button onClick={() => setIsActivityFormOpen(true)} variant="outlined">
          {fMessage({
            id: "Activity.Common.HelpText.CreateActivity",
          })}
        </Button>
      </Grid>
      <Grid container item md={12} spacing={2}>
        <Loadable isLoading={isLoading || isLoadingTags}>
          {filteredItems
            .slice(start, end)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((activity) => (
              <Fade key={activity.id} in timeout={1000}>
                <Grid item xs={12} md={4}>
                  <ActivityCard
                    activity={activity}
                    onDeleteCallback={onDeleteCallback}
                    onEditCallback={(activityId) => {
                      setActiveActivity((prev) => ({
                        ...prev,
                        id: activityId,
                      }));
                      setIsEditFormOpen(true);
                    }}
                    key={activity.id}
                  />
                </Grid>
              </Fade>
            ))}
        </Loadable>
      </Grid>
      <Grid container item alignSelf="center" justifyContent={"flex-end"}>
        <Pagination
          onChange={handlePageChange}
          count={Math.ceil((filteredItems.length) / PAGE_SIZE)}
        />
      </Grid>
      <AddActivityDialog
        isOpen={isActivityFormOpen}
        onCloseCallback={() => setIsActivityFormOpen(false)}
        tags={tags ?? []}
        onActivityCreatedCallback={(activityId, pictureUrl) => {
          setActiveActivity({ id: activityId, pictureUrl });
          setIsFileFormOpen(true);
        }}
      />
      <AddFileDialog
        isOpen={isFileFormOpen}
        onCloseCallback={(refresh) => {
          setIsFileFormOpen(false);
          if (refresh) refetch();
        }}
        activityId={activeActivity.id}
        defaultSrc={activeActivity.pictureUrl}
      />
      {currentActivity && (
        <EditActivityDialog
          isOpen={isEditFormOpen}
          onCloseCallback={() => setIsEditFormOpen(false)}
          onActivityEditCallback={() => {
            setActiveActivity({ id: "", pictureUrl: "" });
            refetch();
          }}
          activityId={currentActivity.id}
          tags={tags ?? []}
          key={currentActivity.id}
        />
      )}
    </Grid>
  );
};

export default Activities;
