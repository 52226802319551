import { TagResponse, TagUpdateRequest } from "../types/types";
import { api } from "./api";
import { getEnv } from "../getEnv";

const basePath = `${getEnv().API_PATH_ACTIVITIES}/activities/tags`;

const tagApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTags: builder.query<TagResponse[], void>({
      query: () => ({
        url: basePath,
        method: "GET",
      }),
      providesTags: ["Tag"],
    }),
    getTag: builder.query<TagResponse, string>({
      query: (id) => ({
        url: `/${id}`,
        method: "GET",
      }),
    }),
    createTag: builder.mutation<TagResponse, TagUpdateRequest>({
      query: (req) => ({
        url: basePath,
        method: "POST",
        body: req,
      }),
      invalidatesTags: ["Tag"],
    }),
    updateTag: builder.mutation<
      TagResponse,
      { id: string; request: TagUpdateRequest }
    >({
      query: (req) => ({
        url: `${basePath}/${req.id}`,
        method: "PUT",
        body: req.request,
      }),
      invalidatesTags: ["Tag"],
    }),
    deleteTag: builder.mutation<TagResponse, { id: string }>({
      query: (req) => ({
        url: `${basePath}/${req.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Tag"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTagQuery,
  useGetTagsQuery,
  useCreateTagMutation,
  useUpdateTagMutation,
  useDeleteTagMutation,
} = tagApi;
