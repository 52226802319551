import { CardContent, Chip, Typography, Card, Grid } from "@mui/material";
import React, { FC } from "react";

interface IDashboardCard {
  title: string;
  count: number;
  userCount: number;
  changeLabel: string;
}

const DashboardCard: FC<IDashboardCard> = ({
  title,
  count,
  userCount,
  changeLabel,
}) => {
  return (
    <Card sx={{ minWidth: 275 }} variant="outlined">
      <CardContent>
        <Typography fontSize="18px">{title}</Typography>
        <Typography variant="h3" component="div" color="#000">
          {count}
        </Typography>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="body2">{`${userCount} Users`}</Typography>
          <Chip label={changeLabel} style={{ backgroundColor: "#FFEFE7" }} />
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DashboardCard;
