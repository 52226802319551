import { useSnackbar } from "notistack";
import { useIntl } from "react-intl";
import {
  ActivityCreateRequest,
  TagResponse,
  ActivityResponse,
} from "../../../types/types";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { SerializedError } from "@reduxjs/toolkit";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import { Formik, FormikHelpers } from "formik";
import React, { FC } from "react";
import { useCreateActivityMutation } from "../../../services/activities";
import { TimespanInput } from "./Inputs";
import {
  FormTextInput,
  MultiSelectFormInput,
} from "../../../components/common";
import { Loadable } from "../../../components/loadable/Loadable";
import * as yup from "yup";

interface IAddActivityDialog {
  onCloseCallback: () => void;
  onActivityCreatedCallback: (activityId: string, pictureUrl: string) => void;
  isOpen: boolean;
  tags: Array<TagResponse>;
}

export const AddActivityDialog: FC<IAddActivityDialog> = ({
  onCloseCallback,
  isOpen,
  tags,
  onActivityCreatedCallback,
}) => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [createActivity, { isLoading }] = useCreateActivityMutation();

  const handleCreateActivity = (
    values: ActivityCreateRequest,
    helpers: FormikHelpers<ActivityCreateRequest>
  ) => {
    createActivity(values)
      .unwrap()
      .then((res: ActivityResponse) => {
        const { resetForm } = helpers;
        enqueueSnackbar(
          intl.formatMessage({ id: "Activity.AddActivity.Success" }),
          { variant: "success" }
        );
        onActivityCreatedCallback(res.id, res.imageUrl);
        resetForm();
      })
      .catch((err: FetchBaseQueryError | SerializedError) => {
        if ("data" in err && err.status === 400) {
          enqueueSnackbar(
            intl.formatMessage({
              id: "Activity.ActivityForm.Error.ValidationErrors",
            }),
            { variant: "error" }
          );
        } else if ("data" in err && err.status === 409) {
          enqueueSnackbar(
            intl.formatMessage({
              id: "Activity.ActivityForm.Error.ActivityExists",
            }),
            { variant: "error" }
          );
        } else {
          enqueueSnackbar(
            intl.formatMessage({ id: "Activity.AddActivity.Error" }),
            { variant: "error" }
          );
        }
      })
      .finally(() => onCloseCallback());
  };

  return (
    <Formik
      initialValues={{
        name: "",
        description: "",
        selectedTagIds: [] as Array<string>,
        timeSpanDays: 1,
      }}
      onSubmit={handleCreateActivity}
      validationSchema={yup.object().shape({
        name: yup
          .string()
          .min(1, (val) =>
            intl.formatMessage(
              { id: "Validation.MinLengthNamed" },
              {
                name: intl.formatMessage({
                  id: "Activity.ActivityForm.ActivityName",
                }),
                x: val.min,
              }
            )
          )
          .max(32, (val) =>
            intl.formatMessage(
              { id: "Validation.MaxLengthNamed" },
              {
                name: intl.formatMessage({
                  id: "Activity.ActivityForm.ActivityName",
                }),
                x: val.max,
              }
            )
          )
          .required(intl.formatMessage({ id: "Validation.FieldRequired" })),
        description: yup
          .string()
          .min(1, (val) =>
            intl.formatMessage(
              { id: "Validation.MinLengthNamed" },
              {
                name: intl.formatMessage({
                  id: "Activity.ActivityForm.Description",
                }),
                x: val.min,
              }
            )
          )
          .required(intl.formatMessage({ id: "Validation.FieldRequired" })),
        selectedTagIds: yup
          .array()
          .min(1, (val) =>
            intl.formatMessage(
              { id: "Validation.MinOneSelected" },
              {
                name: intl
                  .formatMessage({
                    id: "Common.Label.Category",
                  })
                  .toLowerCase(),
              }
            )
          )
          .required(intl.formatMessage({ id: "Validation.FieldRequired" })),
      })}
    >
      {({ handleSubmit, errors, setFieldValue }) => (
        <Dialog onClose={onCloseCallback} open={isOpen}>
          <DialogTitle>
            {intl.formatMessage({
              id: "Activity.AddActivity.HelpText.CreateActivity",
            })}
          </DialogTitle>
          <DialogContent>
            <DialogContentText paddingBottom={2}>
              {intl.formatMessage({ id: "Activity.ActivityForm.HelpText" })}
            </DialogContentText>
            <Grid container spacing={1}>
              <FormTextInput
                name="name"
                required
                label="Activity.ActivityForm.ActivityName"
                inputRef={input => input && input.focus()}
              />
              <FormTextInput
                name="description"
                label="Activity.ActivityForm.Description"
                required
                multiline
                maxRows={5}
              />
              <MultiSelectFormInput
                name="selectedTagIds"
                label="Common.Label.Categories"
                required
                options={tags.map((tag) => ({
                  label: tag.name,
                  value: tag.id,
                }))}
              />
              <TimespanInput
                name="timeSpanDays"
                label="Activity.ActivityForm.Timespan"
                onChangeOptionValue={(val: number) =>
                  setFieldValue("timeSpanDays", val)
                }
              />
            </Grid>
            <Loadable isLoading={isLoading} />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={(event) => handleSubmit()}>
              {intl.formatMessage({
                id: "Activity.AddActivity.Button.AddActivity",
              })}
            </Button>
            <Button variant="outlined" color="error" onClick={onCloseCallback}>
              {intl.formatMessage({ id: "Common.Button.Cancel" })}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};
