import React, {useState} from "react";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import {
    Button, Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid, InputAdornment,
    TextField,
} from "@mui/material";
import { FastField, FieldProps, Formik, FormikHelpers } from "formik";
import * as yup from "yup";
import {useCreateCustomerMutation} from "../../services/customers";
import {CustomerDataRequest} from "../../types/types";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import {Add} from "@mui/icons-material";
interface IAddCustomerDialog {
    onClose: () => void;
    open: boolean;
}

type FormValues = Omit<Required<CustomerDataRequest>, 'approvedDomains'> & {
    approvedDomains: string[];
    newDomain: string;
};
const AddCustomerDialog: React.FC<IAddCustomerDialog> = ({ onClose, open }) => {
    const intl = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const [createCustomer] = useCreateCustomerMutation();

    const handleCreateCustomer = (
        values: FormValues,
        helpers: FormikHelpers<FormValues>
    ) => {
        const { resetForm } = helpers;
        const customerData: CustomerDataRequest = {
            ...values,
            approvedDomains: values.approvedDomains.join(','),
        };

        createCustomer(customerData)
            .unwrap()
            .then(() => {
                enqueueSnackbar(
                    intl.formatMessage({ id: "CustomerManagement.AddCustomer.Success" }),
                    { variant: "success" }
                );
                resetForm();
                onClose();
            })
            .catch(() => {
                enqueueSnackbar(
                    intl.formatMessage({ id: "CustomerManagement.AddCustomer.Error" }),
                    { variant: "error" }
                );
            });
    };

    const initialValues: FormValues = {
        name: "",
        approvedDomains: [],
        isActive: true,
        silentPeriodStartHour: 21,
        silentPeriodStartMinute: 0,
        silentPeriodEndHour: 8,
        silentPeriodEndMinute: 0,
        newDomain: ""
    };


    return (
        <Formik
            initialValues={initialValues}
            validationSchema={yup.object().shape({
                name: yup.string().required(intl.formatMessage({ id: "Validation.FieldRequired" })),
                approvedDomains: yup.array().of(yup.string()).min(1, intl.formatMessage({ id: "Validation.MinOneApprovedDomain" })),
                silentPeriodStartHour: yup.number().min(0).max(23).required(intl.formatMessage({ id: "Validation.FieldRequired" })),
                silentPeriodStartMinute: yup.number().min(0).max(59).required(intl.formatMessage({ id: "Validation.FieldRequired" })),
                silentPeriodEndHour: yup.number().min(0).max(23).required(intl.formatMessage({ id: "Validation.FieldRequired" })),
                silentPeriodEndMinute: yup.number().min(0).max(59).required(intl.formatMessage({ id: "Validation.FieldRequired" })),
            })}
            onSubmit={handleCreateCustomer}
        >
            {({ handleSubmit, errors, values, setFieldValue, touched }) => (
                <Dialog onClose={onClose} open={open}>
                    <DialogTitle>
                        {intl.formatMessage({
                            id: "CustomerManagement.AddCustomer.HelpText.CreateCustomer",
                        })}
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FastField name="name">
                                    {({ field }: FieldProps) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            label={intl.formatMessage({
                                                id: "CustomerManagement.Common.Name",
                                            })}
                                            error={!!errors.name}
                                            helperText={errors.name}
                                        />
                                    )}
                                </FastField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label={intl.formatMessage({
                                        id: "CustomerManagement.Common.ApprovedDomains",
                                    })}
                                    value={values.newDomain || ''}
                                    onChange={(e) => setFieldValue('newDomain', e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter' && values.newDomain) {
                                            e.preventDefault();
                                            setFieldValue('approvedDomains', [...values.approvedDomains, values.newDomain]);
                                            setFieldValue('newDomain', '');
                                        }
                                    }}
                                    error={touched.approvedDomains && !!errors.approvedDomains}
                                    helperText={touched.approvedDomains && errors.approvedDomains as string}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => {
                                                        if (values.newDomain) {
                                                            setFieldValue('approvedDomains', [...values.approvedDomains, values.newDomain]);
                                                            setFieldValue('newDomain', '');
                                                        }
                                                    }}
                                                    edge="end"
                                                >
                                                    <Add />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 1 }}>
                                    {values.approvedDomains.map((domain, index) => (
                                        <Chip
                                            key={index}
                                            label={domain}
                                            onDelete={() => {
                                                const newDomains = values.approvedDomains.filter((_, i) => i !== index);
                                                setFieldValue('approvedDomains', newDomains);
                                            }}
                                        />
                                    ))}
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <FastField name="silentPeriodStartHour">
                                    {({ field }: FieldProps) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            type="number"
                                            label={intl.formatMessage({
                                                id: "CustomerManagement.Common.SilentPeriodStartHour",
                                            })}
                                            error={!!errors.silentPeriodStartHour}
                                            helperText={errors.silentPeriodStartHour}
                                        />
                                    )}
                                </FastField>
                            </Grid>
                            <Grid item xs={6}>
                                <FastField name="silentPeriodStartMinute">
                                    {({ field }: FieldProps) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            type="number"
                                            label={intl.formatMessage({
                                                id: "CustomerManagement.Common.SilentPeriodStartMinute",
                                            })}
                                            error={!!errors.silentPeriodStartMinute}
                                            helperText={errors.silentPeriodStartMinute}
                                        />
                                    )}
                                </FastField>
                            </Grid>
                            <Grid item xs={6}>
                                <FastField name="silentPeriodEndHour">
                                    {({ field }: FieldProps) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            type="number"
                                            label={intl.formatMessage({
                                                id: "CustomerManagement.Common.SilentPeriodEndHour",
                                            })}
                                            error={!!errors.silentPeriodEndHour}
                                            helperText={errors.silentPeriodEndHour}
                                        />
                                    )}
                                </FastField>
                            </Grid>
                            <Grid item xs={6}>
                                <FastField name="silentPeriodEndMinute">
                                    {({ field }: FieldProps) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            type="number"
                                            label={intl.formatMessage({
                                                id: "CustomerManagement.Common.SilentPeriodEndMinute",
                                            })}
                                            error={!!errors.silentPeriodEndMinute}
                                            helperText={errors.silentPeriodEndMinute}
                                        />
                                    )}
                                </FastField>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="primary">
                            {intl.formatMessage({ id: "Common.Button.Cancel" })}
                        </Button>
                        <Button onClick={() => handleSubmit()} color="primary" variant="contained">
                            {intl.formatMessage({ id: "CustomerManagement.AddCustomer.Button.AddCustomer" })}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Formik>
    );
};

export default AddCustomerDialog;
