import React, { FC } from "react";
import { Grid, MenuItem, TextField, TextFieldProps } from "@mui/material";
import { FastField, FieldProps } from "formik";
import { useIntl } from "react-intl";

interface IMultiSelectFormInput {
  name: string;
  label: string;
  options: Array<{ value: string; label: string }>;
  disabled?: boolean;
}

export const MultiSelectFormInput: FC<IMultiSelectFormInput & TextFieldProps> = ({
  name,
  label,
  options,
  disabled,
  ...rest
}) => {
  const intl = useIntl();
  return (
    <Grid item xs={12}>
      <FastField name={name}>
        {({ field, meta}: FieldProps) => (
          <TextField
            {...field}
            {...rest}
            select
            SelectProps={{
              multiple: true,
            }}
            fullWidth
            variant="outlined"
            label={intl.formatMessage({
              id: label,
            })}
            error={meta.touched && !!meta.error}
            helperText={meta.error}
            onChange={disabled ? () => {} : field.onChange}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        )}
      </FastField>
    </Grid>
  );
};
