import { Container } from "@mui/material";
import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";

export default function UnprotectedLayout() {
  const auth = useAppSelector(state => state.auth);

  if (auth && auth.isLoggedIn) {
    return (
      <Navigate
        to={{
          pathname: "/dashboard",
        }}
      />
    );
  }
  return (
      <Outlet />
  );
}
