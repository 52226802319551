import React, { FC } from "react";
import { Grid, TextField, TextFieldProps } from "@mui/material";
import { FastField, FieldProps } from "formik";
import { useIntl } from "react-intl";

interface IFormTextInput {
  name: string;
  label: string;
}

export const FormTextInput: FC<IFormTextInput & TextFieldProps> = ({
  name,
  label,
  ...rest
}) => {
  const intl = useIntl();
  return (
    <Grid item xs={12}>
      <FastField name={name}>
        {({ field, meta}: FieldProps) => (
          <TextField
            {...field}
            {...rest}
            fullWidth
            variant="outlined"
            label={intl.formatMessage({
              id: label,
            })}
            error={meta.touched && !!meta.error}
            helperText={meta.error}
          />
        )}
      </FastField>
    </Grid>
  );
};
