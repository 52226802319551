import React, { FC } from "react";
import { Autocomplete, TextField, Typography } from "@mui/material";
import { useIntl } from "react-intl";

interface ISearchInput {
  onChangeCallback: (
    e: React.SyntheticEvent<Element, Event>,
    value: string
  ) => void;
  id: string;
  options: Array<{
    id: string;
    name: string;
  }>;
  placeholderTextId: string;
}

export const SearchInput: FC<ISearchInput> = ({
  onChangeCallback,
  options,
  id,
  placeholderTextId,
}) => {
  const intl = useIntl();

  return (
    <Autocomplete
      disablePortal
      onInputChange={onChangeCallback}
      id={id}
      options={options ?? []}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          label={intl.formatMessage({
            id: placeholderTextId,
          })}
        />
      )}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            <Typography variant="body1" color="text.secondary">
              {option.name}
            </Typography>
          </li>
        );
      }}
    />
  );
};
