import { alpha, createTheme, Theme } from "@mui/material/styles/";

const lightTheme: Theme = createTheme({
  palette: {
    primary: {
      main: "#FF5151",
    },
    pink: {
      main: "#FFD3DE",
    },
    rosewhite: {
      main: "#FFEFE7",
    },
    text: {
      primary: "#686868",
      disabled: "",
      tertiary: {
        main: "#161E54",
      },
      placeholder: {
        main: "#B3B3B3",
      },
      primarySubtitle: {
        main: "#686868",
      },
      secondaryTitle: { main: "#303030" },
      secondarySubtitle: { main: "#686868" },
      pink: {main: "#FF5151", dark: "#FF3366"},
      darkpink: { main: "#69172C" },
    },
    icon: {
      primary: "#B2B2B2",
      secondary: "#FF5151",
    },
  },
  typography: {
    h6: {
      fontSize: "1.125rem",
      fontWeight: 700,
      fontFamily: "Raleway",
      lineHeight: 1.75,
      color: alpha("#000000", 0.8)
    },
    h5: {
      fontSize: "1.5rem",
      fontWeight: 700,
      fontFamily: "Raleway",
      lineHeight: 1.25,
      color: alpha("#000000", 0.8)
    },
    h4: {
      fontSize: "2.225rem",
      fontWeight: 700,
      fontFamily: "Roboto",
      color: alpha("#000000", 0.8)
    }
  },
  /*typography: {
    caption: {
      fontSize: "075rem",
      lineHeight: "1rem"
    },
    body2: {
      fontSize: "0.875rem",
      lineHeight: "1.25rem"
    },
    h1: {
      fontSize: "3.75em",
      lineHeight: "3.75rem",
    },
    h2: {
      fontSize: "6rem",
      lineHeight: "6rem",
    },
    h3: {
      fontSize: "4.5rem",
      lineHeight: "4.5rem",
    },
    h4: {
      fontSize: "3.75rem",
      lineHeight: "3.75rem",
    },
    h5: {
      fontSize: "3rem",
      lineHeight: "3rem",
    },
    h6: {
      fontSize: "2.25rem",
      lineHeight: "2.5rem",
    },
    subtitle1: {
      fontSize: "1.875rem",
      lineHeight: "2.25rem",
    },
    subtitle2: {
      fontSize: "1.5rem",
      lineHeight: "2rem",
    },
    overline: {
      fontSize: "1.25rem",
      lineHeight: "1.75rem",
    },
    button: {
      fontSize: "1.125rem",
      lineHeight: "1.75rem",
    }
  },*/
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#FFF",
        },
        root: ({ theme }) => ({
          boxShadow: "none",
          borderBottom: `1px solid ${theme.palette.divider}`,
        }),
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          //rounded corners
          borderRadius: "10px"
        }
      }
    }
  },
});

export default lightTheme;
