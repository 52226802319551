import { Avatar, FormHelperText, Tooltip, IconButton } from "@mui/material";
import { FastField, FieldProps } from "formik";
import React, { FC, ReactElement, useState } from "react";
import { FileUpload } from "@mui/icons-material";
import { Stack } from "@mui/system";
interface IUploadFileField {
  name: string;
  src?: string;
  size?: string | number;
  placeholder?: ReactElement;
  helperText?: string;
}

const UploadFileField: FC<IUploadFileField> = ({
  name,
  helperText,
  src,
  placeholder = <FileUpload fontSize={"large"} />,
  size = "24px",
}) => {
  const [imageSrc, setImageSrc] = useState<string | undefined>(src);

  return (
    <FastField name={name}>
      {({
        field,
        meta: { error, touched },
        form: { setFieldValue, setFieldTouched },
      }: FieldProps<File>) => (
        <Tooltip placement="right" title="Select an image from your machine">
          <Stack>
            <IconButton
              component={"label"}
              sx={{ alignSelf: "center", width: "fit-content" }}
            >
              <input
                id={"file-upload"}
                type="file"
                accept=".jpeg, .jpg, .jpe, .png, .webp"
                hidden
                onChange={(event) => {
                  const { files } = event.target;
                  if (files && files.length > 0) {
                    setFieldValue(name, files[0]);
                    setImageSrc(URL.createObjectURL(files[0]));
                    setFieldTouched(name, true);
                  }
                }}
              />
              {imageSrc ? (
                <Avatar
                  sx={{
                    position: "relative",
                    height: size,
                    width: size,
                    borderRadius: "50%",
                  }}
                  imgProps={{
                    style: {
                      objectFit: "cover",
                    },
                  }}
                  alt={field.value ? `${field.value.name} - image` : ""}
                  src={imageSrc}
                />
              ) : (
                React.cloneElement(placeholder, { height: size, width: size })
              )}
            </IconButton>
            {error && touched && !field.value ? (
              <FormHelperText>{error}</FormHelperText>
            ) : (
              <FormHelperText>{helperText}</FormHelperText>
            )}
          </Stack>
        </Tooltip>
      )}
    </FastField>
  );
};

export default UploadFileField;
