import React, { FC } from "react";
import { Grid, FormHelperText, InputLabel } from "@mui/material";
import { FastField, FieldProps } from "formik";
import { TimeInput } from "../../../../components/common";

interface IFormTimeInput {
  name: string;
  label: string;
  hasError: boolean;
  helperText?: string;
  onChange: (val: string) => void;
}

export const FormTimeInput: FC<IFormTimeInput> = ({
  name,
  label,
  hasError,
  helperText,
  onChange,
}) => {
  return (
    <Grid item xs={12}>
      <FastField name={name}>
        {({ field }: FieldProps<string>) => (
          <Grid item>
            <InputLabel>{label}</InputLabel>
            <TimeInput
              value={field.value}
              onChangeCallback={onChange}
              hasError={hasError}
            />
            {hasError && (
              <FormHelperText style={{ color: "red" }}>
                {helperText}
              </FormHelperText>
            )}
          </Grid>
        )}
      </FastField>
    </Grid>
  );
};
