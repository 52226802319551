import { StatisticsResponse, StatisticsRequest } from "../types/types";
import { api } from "./api";
import { getEnv } from "../getEnv";

const basePath = `${getEnv().API_PATH_ACTIVITIES}/activities/statistics`;

const statisticsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getStatistics: builder.query<StatisticsResponse, StatisticsRequest>({
      query: (req) => ({
        url: basePath,
        method: "POST",
        body: req,
      }),
      providesTags: ["Statistics"],
    }),
  }),
  overrideExisting: false,
});

export const { useGetStatisticsQuery } = statisticsApi;
