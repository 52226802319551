import { LoginRequest } from "./../types/types";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import { BaseQueryApi } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { RootState } from "../redux/store";
import authSlice from "../redux/reducers/authReducer";
import { LoginResponse } from "../types/types";
import { getEnv } from "../getEnv";

const basePath = `${getEnv().API_PATH_AUTH}/authentication`;

//Attach token to every request
const prepareHeaders = (
  headers: Headers,
  { getState }: Pick<BaseQueryApi, "getState">
) => {
  const {
    auth: { accessToken },
  } = getState() as RootState;
  if (accessToken) {
    headers.set("authorization", `Bearer ${accessToken}`);
  }
  return headers;
};

const baseQuery = fetchBaseQuery({
  baseUrl: basePath,
  prepareHeaders: prepareHeaders,
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const { signIn, signOut } = authSlice.actions;
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // request new token
    const {
      auth: { accessToken, refreshToken },
    } = api.getState() as RootState;

    if (accessToken && refreshToken) {
      const refreshResult = await baseQuery(
        {
          url: "/refresh",
          method: "POST",
          body: {
            accessToken: accessToken,
            refreshToken: refreshToken,
          },
        },
        api,
        extraOptions
      );

      if (refreshResult.data && !refreshResult.error) {
        api.dispatch(
          signIn({
            accessToken: (refreshResult.data as LoginResponse).accessToken,
            refreshToken: (refreshResult.data as LoginResponse).refreshToken,
          })
        );
        result = await baseQuery(args, api, extraOptions);
      } else {
        api.dispatch(signOut());
      }
    } else {
      api.dispatch(signOut());
    }
  }
  return result;
};

// API service
export const api = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    "Group",
    "User",
    "Activity",
    "Tag",
    "File",
    "Customer",
    "Statistics",
    "Goal",
  ],
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginRequest>({
      query: (req) => ({
        url: "/admin/login",
        method: "POST",
        body: req,
      }),
    }),
    grantAdmin: builder.mutation<string, string>({
      query: (id) => ({
        url: `${basePath}/admin/access`,
        method: "PATCH",
        body: {
          userId: id
        }
      }),
      invalidatesTags: ["User"],
    })
  }),
});

export const { useLoginMutation, useGrantAdminMutation} = api;
