import { useSnackbar } from "notistack";
import { useIntl } from "react-intl";
import { useCreateTagMutation } from "../../../services/tags";
import { TagResponse, TagUpdateRequest } from "../../../types/types";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { SerializedError } from "@reduxjs/toolkit";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import { FastField, FieldProps, Formik, FormikHelpers } from "formik";
import * as yup from "yup";

interface IAddTagDialog {
  onClose: () => void;
  open: boolean;
}
const AddTagDialog = (props: IAddTagDialog) => {
  const { onClose: handleClose, open, ...rest } = props;
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [createTag, { isLoading }] = useCreateTagMutation();

  const handleCreateTag = (
    values: TagUpdateRequest,
    helpers: FormikHelpers<TagUpdateRequest>
  ) => {
    const { resetForm } = helpers;
    createTag({ name: values.name?.trim(), description: values.description })
      .unwrap()
      .then((res: TagResponse) => {
        enqueueSnackbar(intl.formatMessage({ id: "Category.AddCategory.Success" }), {
          variant: "success",
        });
        resetForm();
        handleClose();
      })
      .catch((err: FetchBaseQueryError | SerializedError) => {
        if ("data" in err && err.status === 409) {
          enqueueSnackbar(
            intl.formatMessage({
              id: "Category.CategoryForm.Error.CategoryExists",
            }),
            { variant: "error" }
          );
        } else {
          enqueueSnackbar(
            intl.formatMessage({
              id: "Category.CategoryForm.Error.ValidationErrors",
            }),
            { variant: "error" }
          );
        }
      });
  };

  return (
    <Formik
      initialValues={{ name: "", description: "" }}
      validationSchema={yup.object().shape({
        name: yup
          .string()
          .max(32, (val) =>
            intl.formatMessage(
              { id: "Validation.MaxLengthNamed" },
              {
                name: intl.formatMessage({
                  id: "Common.Label.Name",
                }),
                x: val.max,
              }
            )
          )
          .required(intl.formatMessage({ id: "Validation.FieldRequired" })),
      })}
      onSubmit={handleCreateTag}
    >
      {({ handleSubmit, errors }) => (
        <Dialog onClose={handleClose} open={open}>
          <DialogTitle>
            {intl.formatMessage({
              id: "Category.Common.HelpText.CreateCategory",
            })}
          </DialogTitle>
          <DialogContent>
            <DialogContentText paddingBottom={2}>
              {intl.formatMessage({ id: "Category.CategoryForm.HelpText" })}
            </DialogContentText>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FastField name="name">
                  {({ field }: FieldProps) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="outlined"
                      label={intl.formatMessage({
                        id: "Common.Label.Name",
                      })}
                      required
                      inputRef={input => input && input.focus()}
                      error={!!errors.name}
                      helperText={errors.name}
                    />
                  )}
                </FastField>
              </Grid>
              <Grid item xs={12}>
                <FastField name="description">
                  {({ field }: FieldProps) => (
                    <TextField
                      {...field}
                      multiline
                      maxRows={5}
                      fullWidth
                      variant="outlined"
                      label={intl.formatMessage({
                        id: "Common.Label.Description",
                      })}
                      helperText={intl.formatMessage({
                        id: "Category.Common.HelpText.Description",
                      })}
                    />
                  )}
                </FastField>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={(event) => handleSubmit()}>
              {intl.formatMessage({
                id: "Category.AddCategory.Button.AddCategory",
              })}
            </Button>
            <Button variant="outlined" color="error" onClick={handleClose}>
              {intl.formatMessage({ id: "Common.Button.Cancel" })}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};

export default AddTagDialog;
