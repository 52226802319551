import { LockOutlined } from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
  Button,
  Grid,
  Stack,
  TextField,
  Box,
  InputAdornment,
  FormHelperText,
  Container,
  Paper,
} from "@mui/material";
import { FastField, FieldProps, Formik } from "formik";
import { useIntl } from "react-intl";
import * as yup from "yup";
import { useLoginMutation } from "../../services/api";
import { useAppDispatch } from "../../redux/hooks";
import authSlice from "../../redux/reducers/authReducer";
import { LoginResponse } from "../../types/types";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { SerializedError } from "@reduxjs/toolkit";
import { useState } from "react";

export interface ISigninProps {}

interface FormData {
  email: string;
  password: string;
}

export default function Signup(props: ISigninProps) {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [login, { error }] = useLoginMutation();
  const [errorMsg, setErrorMsg] = useState<string>("");

  const handleLogin = async (formData: FormData) => {
    login(formData)
      .unwrap()
      .then((payload: LoginResponse) => {
        dispatch(authSlice.actions.signIn(payload));
      })
      .catch((err: FetchBaseQueryError | SerializedError) => {
        if (err && "status"! in err) {
          const errBody = err.data as { message: string };
          setErrorMsg(errBody.message);
        }
      });
  };

  let validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(intl.formatMessage({ id: "Validation.InvalidEmail" }))
      .required(intl.formatMessage({ id: "Validation.FieldRequired" })),
    password: yup
      .string()
      .required(intl.formatMessage({ id: "Validation.FieldRequired" })),
  });

  return (
    <Formik
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        email: "",
        password: "",
      }}
      //enableReinitialize
      onSubmit={handleLogin}
    >
      {({ handleSubmit, errors }) => (
        <Box
          style={{
            backgroundSize: "cover",
            backgroundPosition: "center",
            // backgroundImage: `url(${"assets/images/cover_image.jpeg"})`,
          }}
          sx={{ height: "100vh", width: "100%" }}
        >
          <Container>
            <Grid
              role="form"
              container
              flexDirection={"row"}
              justifyContent={"center"}
              alignItems="center"
              style={{ minHeight: "100vh" }}
            >
              <Grid item xs={12} md={4}>
                <Stack
                  direction="column"
                  justifyContent="space-between"
                  spacing={2}
                  component={Paper}
                  variant="outlined"
                  sx={{ py: 7, px: 3, borderRadius: "30px" }}
                >
                  <Box
                    height={72}
                    sx={{ pb: 4 }}
                    component="img"
                    alt="Masterment"
                    src={"assets/images/masterment_logo.svg"}
                  />
                  <FastField name="email">
                    {({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AccountCircleIcon />
                            </InputAdornment>
                          ),
                        }}
                        type={"text"}
                        label={intl.formatMessage({
                          id: "Login.Input.Label.Email",
                          defaultMessage: "Email",
                        })}
                        inputRef={input => input && input.focus()}
                        error={!!errors.email}
                        helperText={errors.email}
                      />
                    )}
                  </FastField>
                  <FastField name="password">
                    {({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LockOutlined />
                            </InputAdornment>
                          ),
                        }}
                        type={"password"}
                        label={intl.formatMessage({
                          id: "Login.Input.Label.Password",
                          defaultMessage: "Password",
                        })}
                        error={!!errors.password}
                        helperText={errors.password}
                      />
                    )}
                  </FastField>
                  {errorMsg && (
                    <FormHelperText error>{errorMsg}</FormHelperText>
                  )}
                  <Button
                    variant="contained"
                    type={"submit"}
                    onClick={(e) => handleSubmit()}
                  >
                    {intl.formatMessage({ id: "Commom.Label.Login" })}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
    </Formik>
  );
}
