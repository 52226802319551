import { UpdateRequest, User, UserUpdateRequest } from "./../types/types";
import { api } from "./api";
import { getEnv } from "../getEnv";

const basePath = `${getEnv().API_PATH_USERS}/users`;

const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<User[], void>({
      query: () => ({
        url: `${basePath}/admin`,
        method: "GET",
      }),
      providesTags: ["User"],
    }),
    getUser: builder.query<User, string>({
      query: (id) => ({
        url: `${basePath}/${id}`,
        method: "GET",
      }),
    }),
    updateUser: builder.mutation<User, UpdateRequest<UserUpdateRequest>>({
      query: (req) => ({
        url: `${basePath}/admin/${req.id}`,
        method: "PUT",
        body: req.request,
      }),
      invalidatesTags: ["User"],
    }),
    deleteUser: builder.mutation<User, string>({
      query: (id) => ({
        url: `${basePath}/admin/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["User", "Group"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUserQuery,
  useGetUsersQuery,
  useUpdateUserMutation,
  useDeleteUserMutation,
} = userApi;
