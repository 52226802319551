import { CardContent, Typography, Card, Grid } from "@mui/material";
import ListItem from "./ListItem";
import React, { CSSProperties, FC } from "react";

interface IListItem {
  name: string;
  finishedItems: number;
  id: string;
  image: string;
}

interface IDashboardList {
  title: string;
  data: Array<IListItem>;
  style?: CSSProperties;
}

const DashboardList: FC<IDashboardList> = ({ title, data, style }) => {
  return (
    <Card style={{ flex: 1, ...style }} variant="outlined">
      <CardContent>
        <Typography fontSize="18px" marginBottom="10px">
          {title}
        </Typography>
        <Grid
          style={{ overflowY: "auto", height: "475px", paddingRight: "10px" }}
        >
          {data.slice().sort((a, b) => b.finishedItems - a.finishedItems).map((val) => (
            <ListItem key={val.id} {...val} />
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DashboardList;
