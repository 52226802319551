import activity_en from "./activity/en.json";
import common_en from "./common/en.json";
import settings_en from "./settings/en.json";
import dashboard_en from "./dashboard/en.json";
import locale_en from "./en.json";
import locale_nb from "./no_nb.json";
import goal_en from "./goal/en.json";
import categories_en from "./categories/en.json";

const translations = {
  en: {
    ...locale_en,
    ...activity_en,
    ...common_en,
    ...settings_en,
    ...goal_en,
    ...categories_en,
    ...dashboard_en,
  },
  no_nb: locale_nb,
};
export type IntlTranslations = typeof translations;

export { translations };
