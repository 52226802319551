import { useSnackbar } from "notistack";
import { useIntl } from "react-intl";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { SerializedError } from "@reduxjs/toolkit";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { Formik, FormikHelpers } from "formik";
import React, { FC } from "react";
import { useUploadFileMutation } from "../../../services/files";
import { UploadFileField } from "../../../components/common";
import { Loadable } from "../../../components/loadable/Loadable";
import * as yup from "yup";

interface IAddFileDialog {
  onCloseCallback: (refresh?: boolean) => void;
  isOpen: boolean;
  activityId: string;
  defaultSrc?: string;
}

export const AddFileDialog: FC<IAddFileDialog> = ({
  onCloseCallback,
  isOpen,
  defaultSrc,
  activityId,
}) => {
  const {formatMessage: fMessage }= useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [uploadFile, { isLoading }] = useUploadFileMutation();

  const handleUploadFile = (
    values: { image: File },
    helpers: FormikHelpers<{ image: File }>
  ) => {
    uploadFile({
      file: values.image,
      asset: "ActivityImage",
      assetId: activityId,
    })
      .unwrap()
      .then(() => {
        const { resetForm } = helpers;
        enqueueSnackbar(
          fMessage({ id: "Common.File.FileUpload.Success" }),
          { variant: "success" }
        );
        resetForm();
      })
      .catch((err: FetchBaseQueryError | SerializedError) => {
        enqueueSnackbar(
          fMessage({ id: "Common.File.FileUpload.Error" }),
          { variant: "error" }
        );
      })
      .finally(() => onCloseCallback(true));
  };

  return (
    <Formik
      initialValues={{
        image: undefined as unknown as File,
      }}
      onSubmit={handleUploadFile}
      validationSchema={yup.object().shape({
        image: yup
          .mixed()
          .required(fMessage({ id: "Validation.PleaseChooseAnImage" })),
      })}
    >
      {({ handleSubmit, resetForm, values}) => (
        <Dialog onClose={() => onCloseCallback()} open={isOpen}>
          <DialogTitle>
            {fMessage({
              id: "Activity.AddActivity.AddPicture.AddPicture",
            })}
          </DialogTitle>
          <DialogContent>
            <DialogContentText paddingBottom={2}>
              {fMessage({
                id: "Activity.AddActivity.AddPicture.HelpText",
              })}
            </DialogContentText>
            <Grid container item justifyContent={"center"}>
              <UploadFileField name={"image"} size={"100px"} src={defaultSrc} helperText={fMessage({id: "Activity.AddActivity.AddPicture.Instructions"})} />
            </Grid>
            <Grid container item justifyContent={"center"}>
              <Typography variant="caption">{fMessage({
                id: "Activity.AddActivity.AddPicture.HelperText",
              })}</Typography>
            </Grid>
            <Loadable isLoading={isLoading} />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={(event) => handleSubmit()} disabled={typeof values.image === "undefined"}>
              {fMessage({
                id: "Common.File.FileUpload.Submit",
              })}
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={() => {
                onCloseCallback();
                resetForm();
              }}
            >
              {fMessage({ id: "Common.Button.Skip" })}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};
