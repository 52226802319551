import {
  ActivityCreateRequest,
  ActivityResponse,
  ActivityUpdateRequest,
} from "../types/types";
import { api } from "./api";
import { getEnv } from "../getEnv";

const basePath = `${getEnv().API_PATH_ACTIVITIES}/activities`;

const activityApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getActivities: builder.query<Array<ActivityResponse>, void>({
      query: () => ({
        url: basePath,
        method: "GET",
      }),
      providesTags: ["Activity"],
    }),
    getActivity: builder.query<ActivityResponse, {id: string}>({
      query: (req) => ({
        url: `${basePath}/${req.id}`,
        method: "GET",
      }),
      providesTags: (res) => [{type: "Activity", id: res?.id}],
    }),
    createActivity: builder.mutation<ActivityResponse, ActivityCreateRequest>({
      query: (req) => ({
        url: basePath,
        method: "POST",
        body: req,
      }),
      invalidatesTags: ["Activity", "Tag"],
    }),
    deleteActivity: builder.mutation<ActivityResponse, { id: string }>({
      query: (req) => ({
        url: `${basePath}/${req.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Activity", "Tag"],
    }),
    updateActivity: builder.mutation<
      ActivityResponse,
      { id: string; request: ActivityUpdateRequest }
    >({
      query: (req) => ({
        url: `${basePath}/${req.id}`,
        method: "PUT",
        body: req.request,
      }),
      invalidatesTags: ["Activity", "Tag"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetActivitiesQuery,
  useGetActivityQuery,
  useCreateActivityMutation,
  useDeleteActivityMutation,
  useUpdateActivityMutation,
} = activityApi;
