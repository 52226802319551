import {
  Typography,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  Avatar,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingsIcon from "@mui/icons-material/Settings";
import { useConfirm } from "material-ui-confirm";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useDeleteTagMutation } from "../../../services/tags";
import { useSnackbar } from "notistack";
import { TagResponse } from "../../../types/types";
import EditTagDialog from "./EditTagDialog";
import TagDetailDialog from "./TagDetailDialog";
import ClampedTypography from "../../../components/common/ClampedTypography";
interface ITagCard {
  tag: TagResponse;
}
const TagCard = (props: ITagCard) => {
  const { tag } = props;
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const [deleteTag] = useDeleteTagMutation();

  //Associated activities dialog
  const [open, setOpen] = useState<boolean>(false);
  const [manageTagOpen, setManageTagOpen] = useState<boolean>(false);
  const handleCloseManagementForm = () => setManageTagOpen(false);

  // show/hide fullscreen view of card (in modal)
  const [showDetails, setDetailsOpen] = useState<boolean>(false);
  const handleOpenDetails = () => {
    setDetailsOpen(true);
  };
  const handleCloseDetails = () => {
    setDetailsOpen(false);
  };

  const handleDeleteTag = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (tag.tagActivities.length > 0) {
      confirm({
        title:
          intl.formatMessage({
            id: "Category.DeleteCategory.HelpText.CannotDeleteCategoryHeader",
          }),
        description: intl.formatMessage(
          { id: "Category.DeleteCategory.HelpText.CannotDeleteCategory" },
          { name: tag.name }
        ),
        cancellationText: "Ok",
        confirmationButtonProps: {sx: {display: "none"}}
      });
      return;
    }
    confirm({
      title:
        intl.formatMessage({
          id: "Category.DeleteCategory.HelpText.DeleteCategory",
        }) + "?",
      description: intl.formatMessage(
        { id: "Category.DeleteCategory.HelpText.DeleteCategoryWarning" },
        { name: tag.name }
      ),
      confirmationText: intl.formatMessage({
        id: "Category.DeleteCategory.HelpText.DeleteCategory",
      }),
    }).then(() => {
      deleteTag({ id: tag.id })
        .unwrap()
        .then((deleted) => {
          enqueueSnackbar(
            intl.formatMessage({ id: "Category.DeleteCategory.Success" }),
            { variant: "success" }
          );
        })
        .catch((err) => {
          if ("status" in err) {
            const errMsg = err.data as { message: string };
            enqueueSnackbar(
              errMsg?.message ??
                intl.formatMessage({ id: "Category.DeleteCategory.Error" }),
              { variant: "error" }
            );
            return;
          }
          enqueueSnackbar(
            intl.formatMessage({ id: "Category.DeleteCategory.Error" }),
            { variant: "error" }
          );
        });
    });
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title={
          <Tooltip title={tag.name} placement="top-start">
            <Typography noWrap variant="h5">
              {tag.name}
            </Typography>
          </Tooltip>
        }
        sx={{
          display: "flex",
          overflow: "hidden",
          "& .MuiCardHeader-content": {
            overflow: "hidden",
          },
        }}
        subheader={
          <Button
            style={{ paddingLeft: 0 }}
            variant="text"
            onClick={() => setOpen(true)}
          >
            {`${tag.tagActivities.length} activities`}
          </Button>
        }
        action={
          <>
            <Tooltip
              title={intl.formatMessage({
                id: "Common.Hints.Delete",
              })}
            >
              <IconButton onClick={handleDeleteTag}>
                <DeleteIcon color={"primary"} />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={intl.formatMessage({
                id: "Common.Hints.Edit",
              })}
            >
              <IconButton onClick={() => setManageTagOpen(true)}>
                <SettingsIcon color="primary" />
              </IconButton>
            </Tooltip>
          </>
        }
      />
      <Divider />
      <CardContent>
        <Grid container justifyContent={"space-between"}>
          <Grid item xs={12}>
            <ClampedTypography
              text={tag.description}
              //Removing click handler expands the card to show all text and makes all sibling cards expand to the same height
              onClick={handleOpenDetails}
              moreText="More Info"
              lessText="Less Info"
              lines={3}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          {intl.formatMessage({ id: "Category.Common.HelpText.Activities" })}
        </DialogTitle>
        <DialogContent>
          {tag.tagActivities.length ? (
            <List>
              {tag.tagActivities.map((activity) => (
                <ListItem key={activity.id}>
                  <ListItemAvatar>
                    <Avatar
                      alt={`${activity.name} - image`}
                      src={activity.imageUrl}
                    />
                  </ListItemAvatar>
                  <ListItemText primary={activity.name} />
                </ListItem>
              ))}
            </List>
          ) : (
            intl.formatMessage({
              id: "Category.Common.HelpText.NoAssociatedActivities",
            })
          )}
        </DialogContent>
      </Dialog>
      <EditTagDialog
        open={manageTagOpen}
        tag={tag}
        onClose={handleCloseManagementForm}
      />
      <TagDetailDialog
        open={showDetails}
        tag={tag}
        onClose={handleCloseDetails}
      />
    </Card>
  );
};

export default TagCard;
