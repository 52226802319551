import { Grid, MenuItem, TextField, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { DAY_OPTIONS } from "./config";
import { useGetGroupsQuery } from "../../services/groups";
import { Loadable } from "../../components/loadable/Loadable";
import { DashboardCard, DashboardList } from "./components";
import { isEqual, sortBy } from "lodash";
import { useGetStatisticsQuery } from "../../services/statistics";

const ALL_TEAMS = "all";

const Dashboard = () => {
  const intl = useIntl();
  const [timespan, setTimespan] = useState(30);
  const [selectedTeams, setSelectedTeams] = useState([ALL_TEAMS]);
  const [fetchedTeams, setFetchedTeams] = useState<Array<string>>([ALL_TEAMS]);
  const { data: groups, isLoading } = useGetGroupsQuery();
  const { data: statisticsData, isLoading: isLoadingStatistics } =
    useGetStatisticsQuery(
      {
        numberOfDays: timespan,
        groupIds:
          fetchedTeams.includes(ALL_TEAMS) && groups
            ? (groups as Array<any>).map((gr) => gr.id)
            : fetchedTeams,
      },
      { skip: !groups || groups.length === 0 }
    );

  const handleTeamChange = (values: Array<string>) => {
    const setterFunction = (prevState: Array<string>) => {
      const isAllSelected = prevState.includes(ALL_TEAMS);
      const isAllBeingSelected = values.includes(ALL_TEAMS);
      if (isAllSelected) {
        const trimmed = [...values].filter((val) => val !== ALL_TEAMS);
        return trimmed;
      }
      if (isAllBeingSelected) {
        return [ALL_TEAMS];
      }
      return values;
    };

    setSelectedTeams((prev) => setterFunction(prev));
  };

  const makeRequest = (days: number) => {
    if (!isEqual(sortBy(fetchedTeams), sortBy(selectedTeams))) {
      setFetchedTeams(selectedTeams);
    }
  };

  const onTimeSpanChange = (val: number) => {
    if (val !== timespan) {
      setTimespan(val);
      console.log("request", { days: val, teams: fetchedTeams });
    }
  };

  const selectedTimespanLabel = useMemo(() => {
    return DAY_OPTIONS.find((val) => val.value === timespan)?.label;
  }, [timespan]);

  const formatGrowthPercentage = (val?: number) => {
    if (val) return `${val}% in ${selectedTimespanLabel}`;
    return "Cannot show calculation";
  };

  return (
    <Grid
      sx={{ pt: 2 }}
      container
      rowGap={2}
      spacing={3}
      justifyContent="flex-start"
    >
      <Loadable isLoading={isLoading || isLoadingStatistics}>
        <Grid
          container
          item
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Typography flex="1" fontSize="18px">
            {intl.formatMessage({ id: "Dashboard.Header.Label" })}
          </Typography>
          <Grid container item justifyContent="flex-end" flex="1" sx={{mb: 3}}>
            <Grid width="162px" height="60px" paddingRight="9px">
              <TextField
                select
                value={selectedTeams}
                SelectProps={{
                  multiple: true,
                  onClose: () => makeRequest(timespan),
                }}
                variant="filled"
                label={intl.formatMessage({
                  id: "Dashboard.Filters.Team",
                })}
                onChange={({ target }) => {
                  handleTeamChange(target.value as unknown as Array<string>);
                }}
                fullWidth
              >
                <MenuItem key="allOption" value={ALL_TEAMS}>
                  Company total
                </MenuItem>
                {groups &&
                  groups.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid width="162px" height="60px">
              <TextField
                select
                value={timespan}
                fullWidth
                variant="filled"
                label={intl.formatMessage({
                  id: "Dashboard.Filters.Time",
                })}
                onChange={({ target }) => {
                  onTimeSpanChange(parseInt(target.value, 10));
                }}
              >
                {DAY_OPTIONS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item justifyContent="space-between" rowGap={3} spacing={2}>
          <Grid item xs={12} md={6} lg={3}>
            <DashboardCard
              title="Activities completed"
              count={statisticsData?.activities.total ?? 0}
              userCount={statisticsData?.activities.numberOfUsers ?? 0}
              changeLabel={formatGrowthPercentage(
                statisticsData?.activities.changePercentage
              )}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <DashboardCard
              title="Goals completed"
              count={statisticsData?.goals.total ?? 0}
              userCount={statisticsData?.goals.numberOfUsers ?? 0}
              changeLabel={formatGrowthPercentage(
                statisticsData?.goals.changePercentage
              )}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <DashboardCard
              title="Categories worked on"
              count={statisticsData?.categories.total ?? 0}
              userCount={statisticsData?.categories.numberOfUsers ?? 0}
              changeLabel={formatGrowthPercentage(
                statisticsData?.categories.changePercentage
              )}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <DashboardCard
              title="Teammates accepted"
              count={statisticsData?.teammates.total ?? 0}
              userCount={statisticsData?.teammates.numberOfUsers ?? 0}
              changeLabel={formatGrowthPercentage(
                statisticsData?.teammates.changePercentage
              )}
            />
          </Grid>
        </Grid>
        <Grid container item justifyContent="space-between" rowGap={3} spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <DashboardList
              title="Categories"
              data={statisticsData?.categories.subStatisticsDtos ?? []}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DashboardList
              title="Activities"
              data={statisticsData?.activities.subStatisticsDtos ?? []}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DashboardList
              title="Goals"
              data={statisticsData?.goals.subStatisticsDtos ?? []}
            />
          </Grid>
        </Grid>
      </Loadable>
    </Grid>
  );
};

export default Dashboard;
