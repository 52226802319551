import {
  Card,
  CardContent,
  Typography,
  Grid,
  CardMedia,
  Dialog,
  DialogContent,
} from "@mui/material";
import React, { FC, useState } from "react";

interface IListItem {
  name: string;
  finishedItems: number;
  image: string;
}

const ListItem: FC<IListItem> = ({ finishedItems, name, image }) => {
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);

  return (
    <Card
      variant="outlined"
      style={{ backgroundColor: "#F9FAFB", marginBottom: "10px" }}
    >
      <CardContent>
        <Grid container alignItems="center">
          {image && (
            <Grid
              item
              width={"75px"}
              sx={{ display: { xs: "block", md: "none", lg: "block" } }}
            >
              <CardMedia
                onClick={() => setPreviewOpen(true)}
                component="img"
                height={"75px"}
                sx={{
                  objectFit: "cover",
                  height: "75px",
                  minWidth: "75px",
                  maxWidth: "75px",
                  cursor: "pointer",
                }}
                image={image}
                alt={name}
              />
            </Grid>
          )}
          <Grid
            container
            item
            xs
            sx={{ pl: 2 }}
            justifyContent="space-between"
          >
            <Grid item xs={10} md={11}>
              <Typography
                variant="h3"
                component="div"
                color="#000"
                fontSize="18px"
              >
                {name}
              </Typography>
            </Grid>
            <Grid item xs={2} md={1}>
              <Typography
                variant="h3"
                component="div"
                color="#000"
                fontSize="28px"
              >
                {finishedItems}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <Dialog open={previewOpen} onClose={() => setPreviewOpen(false)}>
        <DialogContent sx={{ height: "300px", padding: 0 }}>
          <img
            height={"100%"}
            src={image}
            srcSet={image}
            alt={""}
            loading="lazy"
          />
        </DialogContent>
      </Dialog>
    </Card>
  );
};

export default ListItem;
