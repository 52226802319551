import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Grid,
  Pagination,
  TextField,
  Typography,
  CircularProgress,
  Fade,
} from "@mui/material";
import { useIntl } from "react-intl";
import { useGetGroupsQuery } from "../../services/groups";
import {useGetCustomerDataQuery, useGetCustomersQuery} from "../../services/customers";
import TeamSummaryCard from "./components/TeamSummaryCard";
import AddTeamDialog from "./components/AddTeamDialog";
import usePagination from "../../hooks/usePagination";
import { GroupResponse, CustomerDataResponse } from "../../types/types";
import { useAppSelector } from "../../redux/hooks";

export interface ITeamManagementProps {}

const PAGE_SIZE = 9;
const TeamManagement = (props: ITeamManagementProps) => {
  const intl = useIntl();
  const auth = useAppSelector((state) => state.auth);
  const isSuperAdmin = auth.user?.groups.superadmin;

  const { data: customers, isLoading: isLoadingCustomers } = useGetCustomersQuery(undefined, { skip: !isSuperAdmin });
  const { data: currentCustomer, isLoading: isLoadingCurrentCustomer } = useGetCustomerDataQuery(undefined);
  const [selectedCustomer, setSelectedCustomer] = useState<CustomerDataResponse | null>(null);

  const { data: groups, isLoading: isLoadingGroups } = useGetGroupsQuery(selectedCustomer?.id);
  const [filteredItems, setFilteredItems] = useState<GroupResponse[]>([]);

  // console.log(currentCustomer)

  //Add team dialog
  const [open, setOpen] = useState<boolean>(false);
  const handleOpenDialog = () => setOpen(true);
  const handleDialogClose = () => {
    setOpen(false);
  };

  const { start, end, handlePageChange, resetState} = usePagination(PAGE_SIZE);

  //Search input
  const handleSearch = (
      e: React.SyntheticEvent<Element, Event>,
      value: string
  ) => {
    const search = value.toLowerCase();

    setFilteredItems(
        groups?.filter(
            (val) =>
                search.length === 0 ||
                val.name.toLocaleLowerCase().indexOf(search) > -1 ||
                val.description.toLowerCase().indexOf(search) > -1
        ) ?? []
    );
    resetState();
  };

  useEffect(() => {
    if (groups) {
      setFilteredItems(groups);
    }
  }, [groups]);

  useEffect(() => {
    if(!selectedCustomer && currentCustomer) {
      setSelectedCustomer(currentCustomer)
    }
    // if (isSuperAdmin && customers && customers.length > 0 && !selectedCustomer) {
    //   setSelectedCustomer(customers[0]);
    // } else if (!isSuperAdmin && currentCustomer) {
    //   setSelectedCustomer(currentCustomer);
    // }
  }, [customers, currentCustomer, isSuperAdmin, selectedCustomer]);

  if (isLoadingCustomers || isLoadingCurrentCustomer) {
    return <CircularProgress />;
  }

  return (
      <Grid
          sx={{ pt: 2 }}
          container
          rowGap={isSuperAdmin ? undefined : 2}
          spacing={isSuperAdmin ? undefined :3}
          justifyContent="flex-start"
      >

        <Grid item xs={12} md={isSuperAdmin ? 7 : 8}>
          <Autocomplete
              disablePortal
              onInputChange={handleSearch}
              id="group-selector"
              options={groups ?? []}
              fullWidth
              renderInput={(params) => (
                  <TextField
                      {...params}
                      label={intl.formatMessage({
                        id: "TeamManagement.Common.HelpText.Search",
                      })}
                  />
              )}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option) => {
                return (
                    <li {...props} key={option.id}>
                      <Typography variant="body1" color="text.secondary">
                        {option.name}
                      </Typography>
                    </li>
                );
              }}
          />
        </Grid>
        {isSuperAdmin && (
            <Grid item xs={12} md={3}>
              <Autocomplete
                  options={customers ?? []}
                  getOptionLabel={(option: CustomerDataResponse) => option.name}
                  renderInput={(params) => (
                      <TextField
                          {...params}
                          label={intl.formatMessage({
                            id: "TeamManagement.Common.SelectCustomer",
                          })}
                      />
                  )}
                  value={selectedCustomer}
                  onChange={(_, newValue) => setSelectedCustomer(newValue)}
              />
            </Grid>
        )}
        <Grid
            container
            item
            xs={12}
            md={isSuperAdmin ? 2: 4}
            alignSelf="center"
            justifyContent="flex-end"
        >
          <Button onClick={handleOpenDialog} variant="outlined" disabled={!selectedCustomer}>
            {intl.formatMessage({
              id: "TeamManagement.AddTeam.HelpText.CreateTeam",
            })}
          </Button>
        </Grid>
        <Grid container item md={12} spacing={2}>
          {isLoadingGroups || (isSuperAdmin && isLoadingCustomers) ? (
              <CircularProgress
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: 0,
                    bottom: 0,
                    left: 0,
                    margin: "0 auto",
                  }}
              />
          ) : (
              filteredItems
                  .slice(start, end)
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((group) => (
                      <Fade key={group.id} in timeout={1000}>
                        <Grid item xs={12} md={4} alignItems={"stretch"}>
                          <TeamSummaryCard group={group} customerId={selectedCustomer?.id} />
                        </Grid>
                      </Fade>
                  ))
          )}
        </Grid>
        <Grid container item alignSelf="center" justifyContent={"flex-end"}>
          <Pagination
              onChange={handlePageChange}
              count={Math.ceil((filteredItems.length) / 9)}
          />
        </Grid>
        <AddTeamDialog open={open} onClose={handleDialogClose} customerId={selectedCustomer?.id} />
      </Grid>
  );
};

export default TeamManagement;
