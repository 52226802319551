import { Logout, Settings, KeyboardArrowDown } from "@mui/icons-material";
import {
  Avatar,
  Button,
  Grid,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import authSlice from "../../redux/reducers/authReducer";

interface ITopbar {}

const Topbar = (props: ITopbar) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const open = Boolean(anchorEl);
  const location = useLocation();
  const state = location.state as { pageId: string };
  const user = useAppSelector((state) => state.auth.user);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(authSlice.actions.signOut());
  };

  return (
    <Grid container spacing={1} justifyContent="space-between">
      <Grid item>
        <FormattedMessage id={state?.pageId ? state.pageId : "App.Name"}>
          {(name) => (
            <Typography variant="h6" noWrap component="div">
              {name}
            </Typography>
          )}
        </FormattedMessage>
      </Grid>
      <Grid container item md={"auto"} justifySelf="flex-end">
        <Grid item>
          <Avatar>{user?.email.split("@")[0][0].toUpperCase() ?? ''}</Avatar>
        </Grid>
        <Grid item>
          <Button
            color={"secondary"}
            onClick={handleOpenMenu}
            endIcon={<KeyboardArrowDown />}
          >
            {user?.email.split("@")[0] ?? "ADMIN"}
          </Button>
        </Grid>
      </Grid>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleOpenMenu}
        onClick={handleCloseMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {intl.formatMessage({ id: "AccountMenu.Item.Logout" })}
        </MenuItem>
      </Menu>
    </Grid>
  );
};

export default Topbar;
