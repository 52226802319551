//@ts-nocheck
import * as React from "react";
import {
  styled,
  useTheme,
  Grid,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useIntl, FormattedMessage } from "react-intl";
import {
  useDashboardRoutes,
  accountRoutes,
  supportRoute,
} from "../../routing/routes";
import { useLocation, useNavigate } from "react-router-dom";
import { ListSubheader } from "@mui/material";
import { StylizedMenuListItem } from "./StylizedMenuListItem";
import { useDispatch } from "react-redux";
import authSlice from "../../redux/reducers/authReducer";
import { persistor } from "../../redux/store";
import Topbar from "./Topbar";

const drawerWidth = 242;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

interface INavigationDrawerProps {
  children: JSX.Element;
}
const NavigationDrawer = (props: INavigationDrawerProps) => {
  const theme = useTheme();
  const intl = useIntl();
  let location = useLocation();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  // Use the new hook to get dynamic dashboard routes
  const dashboardRoutes = useDashboardRoutes();

  //drawer state
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    dispatch(authSlice.actions.signOut());
    await persistor.purge();
    navigate("/signin");
  };

  const isMenuItemSelected = (path) => location.pathname.endsWith(path);

  return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="fixed" open={open} color="primary">
          <Toolbar>
            <IconButton
                color="default"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton>
            <Topbar />
          </Toolbar>
        </AppBar>
        <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
                px: 1,
              },
            }}
            variant="persistent"
            anchor="left"
            open={open}
        >
          <DrawerHeader>
            <Grid
                style={{ paddingTop: 10 }}
                container
                item
                justifyContent={"space-between"}
                alignItems={"center"}
            >
              <Box
                  component="img"
                  sx={{ height: 73 }}
                  alt="Masterment"
                  src={"/assets/images/masterment_logo.svg"}
              />
              <Grid item>
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === "ltr" ? (
                      <ChevronLeftIcon />
                  ) : (
                      <ChevronRightIcon />
                  )}
                </IconButton>
              </Grid>
            </Grid>
          </DrawerHeader>
          <List>
            <ListSubheader>
              <FormattedMessage id="Menu.Label" />
            </ListSubheader>
            {dashboardRoutes?.map((route) => (
                <StylizedMenuListItem
                    key={route.path}
                    to={route.path}
                    state={{pageId: route.meta?.sidebarId}}
                    primary={intl.formatMessage({
                      id: route.meta?.sidebarId,
                      defaultMessage: "Unnamed menu item",
                    })}
                    icon={route.meta?.icon}
                    selected={isMenuItemSelected(route.path)}
                />
            ))}
          </List>
          <List>
            <ListSubheader>
              <FormattedMessage id="SubMenu.Label" />
            </ListSubheader>
            {accountRoutes?.children?.map((route) => (
                <StylizedMenuListItem
                    key={route.path}
                    to={route?.path ?? "/"}
                    state={{pageId: route.meta?.sidebarId}}
                    primary={intl.formatMessage({
                      id: route.meta?.sidebarId,
                      defaultMessage: "Unnamed menu item",
                    })}
                    icon={route?.meta?.icon}
                    selected={isMenuItemSelected(route.path)}
                />
            ))}
            <ListItem key={"logout"} disablePadding>
              <ListItemButton onClick={handleLogout}>
                <ListItemText primary={"Logout"} />
              </ListItemButton>
            </ListItem>
          </List>
        </Drawer>
        <Main open={open}>
          <DrawerHeader />
          {props.children}
        </Main>
      </Box>
  );
};

export default NavigationDrawer;
