import { combineReducers, PayloadAction } from "@reduxjs/toolkit";
import authReducer from "./authReducer";
import { api } from "../../services/api";

const appReducer = combineReducers({
  auth: authReducer.reducer,
  [api.reducerPath]: api.reducer,
});

const rootReducer = (state: any, action: PayloadAction) => {
  if (action.type === authReducer.actions.signOut.type) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
