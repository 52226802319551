import React, { FC } from "react";
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  CardMedia,
  CardActions,
  Chip,
  CardHeader,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { ActivityResponse } from "../../../types/types";
import ClampedTypography from "../../../components/common/ClampedTypography";
import { useConfirm } from "material-ui-confirm";
import { useIntl } from "react-intl";

interface IActivityCard {
  activity: ActivityResponse;
  onDeleteCallback: (id: string, name: string) => void;
  onEditCallback: (id: string) => void;
}
export const ActivityCard: FC<IActivityCard> = ({
  activity,
  onDeleteCallback,
  onEditCallback,
}) => {
  const confirm = useConfirm();
  const {formatMessage: fMessage} = useIntl();

  const handleDelete = () => {
    if (activity.goals.length > 0) {
      confirm({
        title:
          fMessage({
            id: "Activity.DeleteActivity.HelpText.CannotDeleteActivityHeader",
          }),
        description: fMessage(
          {
            id: "Activity.DeleteActivity.HelpText.CannotDeleteActivity",
          }
        ),
        cancellationText: "Ok",
        confirmationButtonProps: {sx: {display: "none"}}
      })
      return;
    }
    onDeleteCallback(activity.id, activity.name);
  }
  return (
    <Card variant="outlined">
      <CardHeader
        title={activity.name}
        action={
          <>
            <IconButton
              onClick={handleDelete}>
              <DeleteIcon color={"primary"} />
            </IconButton>
            <IconButton onClick={() => onEditCallback(activity.id)}>
              <EditIcon color="primary" />
            </IconButton>
          </>
        }
      />
      <CardMedia
        component="img"
        height="180"
        image={activity.imageUrl}
        alt="green iguana"
      />
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <ClampedTypography
              text={activity.description}
              moreText="More Info"
              lessText="Less Info"
              lines={3}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Grid container spacing={3} style={{ justifyContent: "center" }}>
          {activity.tags.map((tag) => (
            <Grid key={tag.id} item>
              <Chip label={tag.name} size="small" />
            </Grid>
          ))}
        </Grid>
      </CardActions>
    </Card>
  );
};
