import { useSnackbar } from "notistack";
import { useIntl } from "react-intl";
import {
  ActivityResponse,
  GoalResponse,
  GoalUpdateRequest,
} from "../../../types/types";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { SerializedError } from "@reduxjs/toolkit";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import { Formik, FormikHelpers } from "formik";
import React, { FC } from "react";
import { useUpdateGoalMutation } from "../../../services/goals";
import {
  FormTextInput,
  MultiSelectFormInput,
  UploadFileField,
} from "../../../components/common";
import { Loadable } from "../../../components/loadable/Loadable";
import * as yup from "yup";
import { useUploadFileMutation } from "../../../services/files";

interface IEditGoalDialog {
  onCloseCallback: () => void;
  onGoalEditCallback: () => void;
  isOpen: boolean;
  activities: Array<ActivityResponse>;
  goal: GoalResponse;
}

interface IEditForm extends GoalUpdateRequest {
  image: File;
  recommendedByImage: File;
}

export const EditGoalDialog: FC<IEditGoalDialog> = ({
  onCloseCallback,
  isOpen,
  activities,
  onGoalEditCallback,
  goal,
}) => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [updateGoal, { isLoading }] = useUpdateGoalMutation();
  const [uploadFile, rest] = useUploadFileMutation();

  const handleUpdateGoal = (
    values: IEditForm,
    helpers: FormikHelpers<IEditForm>
  ) => {
    const requests: Array<Promise<any>> = [];
    if (values.image) {
      const uploadGoalImageRequest = uploadFile({
        file: values.image,
        asset: "GoalImage",
        assetId: goal.id,
      }).catch((err: FetchBaseQueryError | SerializedError) => {
        enqueueSnackbar(
          intl.formatMessage({
            id: "Common.File.FileUpload.Error",
          }),
          { variant: "error" }
        );
      });

      requests.push(uploadGoalImageRequest);
    }
    if (values.recommendedByImage) {
      const uploadRecommendedByImageRequest = uploadFile({
        file: values.recommendedByImage,
        asset: "GoalRecommendedByImage",
        assetId: goal.id,
      }).catch((err: FetchBaseQueryError | SerializedError) => {
        enqueueSnackbar(
          intl.formatMessage({
            id: "Common.File.FileUpload.Error",
          }),
          { variant: "error" }
        );
      });

      requests.push(uploadRecommendedByImageRequest);
    }

    const updateGoalRequest = updateGoal({
      id: goal.id,
      request: values,
    })
      .unwrap()
      .then((res: GoalResponse) => {
        enqueueSnackbar(intl.formatMessage({ id: "Goal.EditGoal.Success" }), {
          variant: "success",
        });
      })
      .catch((err: FetchBaseQueryError | SerializedError) => {
        if ("data" in err && err.status === 400) {
          enqueueSnackbar(
            intl.formatMessage({
              id: "Goal.GoalForm.Error.ValidationErrors",
            }),
            { variant: "error" }
          );
        } else if ("data" in err && err.status === 409) {
          enqueueSnackbar(
            intl.formatMessage({
              id: "Goal.GoalForm.Error.GoalExists",
            }),
            { variant: "error" }
          );
        } else {
          enqueueSnackbar(intl.formatMessage({ id: "Goal.EditGoal.Error" }), {
            variant: "error",
          });
        }
      });

    requests.push(updateGoalRequest);

    Promise.allSettled(requests).finally(() => {
      const { resetForm } = helpers;
      onGoalEditCallback();
      resetForm();
    });
  };

  return (
    <Formik
      initialValues={{
        name: goal.name,
        description: goal.description,
        recommendedBy: goal.recommendedBy,
        image: undefined as unknown as File,
        recommendedByImage: undefined as unknown as File,
        selectedActivityIds: goal.activities.map((activity) => activity.id),
      }}
      onSubmit={handleUpdateGoal}
      validationSchema={yup.object().shape({
        name: yup
          .string()
          .min(1, (val) =>
            intl.formatMessage(
              { id: "Validation.MinLengthNamed" },
              {
                name: intl.formatMessage({
                  id: "Goal.GoalForm.GoalName",
                }),
                x: val.min,
              }
            )
          )
          .max(32, (val) =>
            intl.formatMessage(
              { id: "Validation.MaxLengthNamed" },
              {
                name: intl.formatMessage({
                  id: "Goal.GoalForm.GoalName",
                }),
                x: val.max,
              }
            )
          )
          .required(intl.formatMessage({ id: "Validation.FieldRequired" })),
        description: yup
          .string()
          .min(1, (val) =>
            intl.formatMessage(
              { id: "Validation.MinLengthNamed" },
              {
                name: intl.formatMessage({
                  id: "Goal.GoalForm.Description",
                }),
                x: val.min,
              }
            )
          )
          .required(intl.formatMessage({ id: "Validation.FieldRequired" })),
        selectedActivityIds: yup
          .array()
          .min(1, (val) =>
            intl.formatMessage(
              { id: "Validation.MinOneSelected" },
              {
                name: intl.formatMessage({
                  id: "Common.Label.Activitity",
                }).toLowerCase(),
              }
            )
          )
          .required(intl.formatMessage({ id: "Validation.FieldRequired" })),
      })}
    >
      {({ handleSubmit, errors, setFieldValue, resetForm }) => (
        <Dialog onClose={onCloseCallback} open={isOpen}>
          <DialogTitle>
            {intl.formatMessage({
              id: "Goal.EditGoal.HelpText.Header",
            })}
          </DialogTitle>
          <DialogContent>
            <DialogContentText paddingBottom={2}>
              {intl.formatMessage({ id: "Goal.GoalForm.HelpText" })}
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid container item>
                <Grid container item xs={8} alignSelf={"center"}>
                  <FormTextInput
                    name="name"
                    label="Goal.GoalForm.GoalName"
                    required
                    inputRef={input => input && input.focus()}
                  />
                </Grid>
                <Grid container item xs={4} justifyContent="center">
                  <UploadFileField
                    name={"image"}
                    src={goal.imageUrl}
                    size={"100px"}
                  />
                </Grid>
              </Grid>
              <FormTextInput
                name="description"
                label="Goal.GoalForm.Description"
                required
                multiline
                maxRows={5}
              />
              <Grid container item>
                <Grid item xs={10}>
                  <FormTextInput
                    name="recommendedBy"
                    label="Goal.GoalForm.RecommendedBy"
                    required
                  />
                </Grid>
                <Grid
                  container
                  item
                  xs={2}
                  alignSelf="center"
                  justifyContent="space-around"
                >
                  <UploadFileField
                    name={"recommendedByImage"}
                    helperText={errors.recommendedByImage as string}
                    src={goal.recommendedByImageUrl}
                    size={"42px"}
                  />
                </Grid>
              </Grid>
              <MultiSelectFormInput
                name="selectedActivityIds"
                label="Goal.GoalForm.Activities"
                required
                options={activities.map((activity) => ({
                  label: activity.name,
                  value: activity.id,
                }))}
              />
            </Grid>
            <Loadable isLoading={isLoading} />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={() => handleSubmit()}>
              {intl.formatMessage({
                id: "Goal.EditGoal.HelpText.Button",
              })}
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={() => {
                onCloseCallback();
                resetForm();
              }}
            >
              {intl.formatMessage({ id: "Common.Button.Cancel" })}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};
