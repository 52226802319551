import { FastField, FieldProps, Formik, FormikHelpers } from "formik";
import { useIntl } from "react-intl";
import { GroupResponse, GroupUpdateRequest } from "../../../types/types";
import * as yup from "yup";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { VariableSizeList } from "react-window";
import { useUpdateGroupMutation } from "../../../services/groups";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { SerializedError } from "@reduxjs/toolkit";
import VisibilitySwitch from "./VisibilitySwitch";

interface IEditTeamDialog {
    group: GroupResponse;
    onClose: () => void;
    open: boolean;
    customerId?: string | null;
}

const EditTeamDialog = (props: IEditTeamDialog) => {
    const { group, onClose: handleClose, open, customerId } = props;
    const { enqueueSnackbar } = useSnackbar();
    const [updateGroup] = useUpdateGroupMutation();

    const intl = useIntl();

    const handleUpdateGroup = (
        values: GroupUpdateRequest,
        helpers: FormikHelpers<GroupUpdateRequest>
    ) => {
        updateGroup({
            id: group.id,
            request: {
                name: values.name.trim(),
                description: values.description,
                isPrivate: values.isPrivate,
            },
            customerId: customerId ?? undefined,
        })
            .unwrap()
            .then((res: GroupResponse) => {
                enqueueSnackbar(
                    intl.formatMessage({ id: "TeamManagement.UpdateTeam.Success" }),
                    { variant: "success" }
                );
                handleClose();
            })
            .catch((err: FetchBaseQueryError | SerializedError) => {
                if ("data" in err && err.status === 409) {
                    enqueueSnackbar(
                        intl.formatMessage({
                            id: "TeamManagement.AddTeam.Error.TeamExists",
                        }),
                        { variant: "error" }
                    );
                } else {
                    enqueueSnackbar(
                        intl.formatMessage({ id: "TeamManagement.UpdateTeam.Error" }),
                        { variant: "error" }
                    );
                }
                helpers.resetForm();
                handleClose();
            });
    };


    return (
    <Formik
      initialValues={{
        name: group.name,
        description: group.description,
        isPrivate: group.isPrivate,
      }}
      onSubmit={handleUpdateGroup}
      validationSchema={yup.object().shape({
        name: yup
          .string()
          .min(1, (val) =>
            intl.formatMessage(
              { id: "Validation.MinLengthNamed" },
              {
                name: intl.formatMessage({
                  id: "TeamManagement.Common.TeamName",
                }),
                x: val.min,
              }
            )
          )
          .max(32, (val) =>
            intl.formatMessage(
              { id: "Validation.MaxLengthNamed" },
              {
                name: intl.formatMessage({
                  id: "TeamManagement.Common.TeamName",
                }),
                x: val.max,
              }
            )
          )
          .required(intl.formatMessage({ id: "Validation.FieldRequired" })),
        description: yup.string().min(1, (val) =>
          intl.formatMessage(
            { id: "Validation.MinLengthNamed" },
            {
              name: intl.formatMessage({
                id: "TeamManagement.Common.Description",
              }),
              x: val.min,
            }
          )
        ),
      })}
    >
      {({ handleSubmit, errors }) => (
        <Dialog onClose={handleClose} open={open}>
          <DialogTitle>
            {intl.formatMessage({
              id: "TeamManagement.EditTeam.HelpText.Header",
            })}
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={1} sx={{ mt: 0.5 }}>
              <Grid item xs={9}>
                <FastField name="name">
                  {({ field }: FieldProps) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="outlined"
                      label={intl.formatMessage({
                        id: "TeamManagement.Common.TeamName",
                      })}
                      required
                      inputRef={(input) => input && input.focus()}
                      error={!!errors.name}
                      helperText={errors.name}
                    />
                  )}
                </FastField>
              </Grid>
              <Grid item xs={3} sx={{ textAlign: "center" }}>
                <Typography variant="caption" component="span" textTransform={"uppercase"}>
                {intl.formatMessage({
                    id: "TeamManagement.Common.PrivateTeam",
                  })}:
                </Typography>
                <FastField name="isPrivate">
                  {({ field, form }: FieldProps) => (
                    <VisibilitySwitch
                      {...field}
                      checked={field.value}
                      onChange={(
                        e: React.ChangeEvent<HTMLInputElement>,
                        checked
                      ) => form.setFieldValue("isPrivate", checked)}
                    />
                  )}
                </FastField>
              </Grid>
              <Grid item xs={12}>
                <FastField name="description">
                  {({ field }: FieldProps) => (
                    <TextField
                      {...field}
                      multiline
                      maxRows={5}
                      fullWidth
                      variant="outlined"
                      label={intl.formatMessage({
                        id: "Common.Label.Description",
                      })}
                      error={!!errors.description}
                      helperText={errors.description}
                    />
                  )}
                </FastField>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 5 }}>
              <Typography variant="overline" sx={{ ml: 0.5 }}>
                Users
              </Typography>
              <Box
                sx={{
                  px: 2,
                  width: "100%",
                  maxHeight: 250,
                  bgcolor: "background.paper",
                }}
              >
                {group.groupUsers?.length ? (
                  <VariableSizeList
                    itemData={group.groupUsers}
                    height={250}
                    width={"100%"}
                    itemSize={(index) => index}
                    itemCount={group.groupUsers.length}
                    overscanCount={5}
                  >
                    {renderRow}
                  </VariableSizeList>
                ) : (
                  <Typography sx={{ mt: 3 }} variant="body2">
                    {intl.formatMessage({
                      id: "TeamManagement.EditTeam.HelpText.EmptyTeam",
                    })}
                  </Typography>
                )}
              </Box>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={(event) => handleSubmit()}>
              {intl.formatMessage({
                id: "TeamManagement.UpdateTeam.Button.UpdateTeam",
              })}
            </Button>
            <Button variant="outlined" color="error" onClick={handleClose}>
              {intl.formatMessage({ id: "Common.Button.Cancel" })}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};

function renderRow(props: any) {
  const { index, data } = props;

  const user = data[index];
  return (
    <ListItem sx={{ px: 0 }} key={data[index].id}>
      <ListItemText>{`${user.firstName} ${user.lastName}`}</ListItemText>
    </ListItem>
  );
}

export default EditTeamDialog;
