import {
    GoalCreateRequest,
    GoalResponse,
    GoalUpdateRequest,
  } from "../types/types";
  import { api } from "./api";
  import { getEnv } from "../getEnv";
  
  const basePath = `${getEnv().API_PATH_ACTIVITIES}/activities/goals`;
  
  const goalApi = api.injectEndpoints({
    endpoints: (builder) => ({
      getGoals: builder.query<Array<GoalResponse>, void>({
        query: () => ({
          url: basePath,
          method: "GET",
        }),
        providesTags: ["Goal"],
      }),
      createGoal: builder.mutation<GoalResponse, GoalCreateRequest>({
        query: (req) => ({
          url: basePath,
          method: "POST",
          body: req,
        }),
        //Activity: An activitity is not deletable when it has one or more activities assigned to it
        invalidatesTags: ["Goal", "Tag", "Activity"],
      }),
      deleteGoal: builder.mutation<GoalResponse, { id: string }>({
        query: (req) => ({
          url: `${basePath}/${req.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Goal", "Tag", "Activity"],
      }),
      updateGoal: builder.mutation<
        GoalResponse,
        { id: string; request: GoalUpdateRequest }
      >({
        query: (req) => ({
          url: `${basePath}/${req.id}`,
          method: "PUT",
          body: req.request,
        }),
        invalidatesTags: ["Goal", "Tag", "Activity"],
      }),
    }),
    overrideExisting: false,
  });
  
  export const {
    useGetGoalsQuery,
    useCreateGoalMutation,
    useDeleteGoalMutation,
    useUpdateGoalMutation,
  } = goalApi;
  