import React, { FC } from "react";
import TimePicker from "react-time-picker";
import "./style.css";

interface ITimeInput {
  disabled?: boolean;
  value: string | null;
  onChangeCallback: (val: string) => void;
  hasError?: boolean;
}

const TimeInput: FC<ITimeInput> = ({
  disabled,
  value,
  onChangeCallback,
  hasError,
}) => {
  return (
    <TimePicker
      onChange={(val) => {
        if (val) onChangeCallback(val.toString());
        else onChangeCallback(val);
      }}
      value={value as any}
      disableClock
      className={`timeInput ${hasError ? "hasError" : ""}`}
      disabled={disabled}
      clearIcon={null}
      hourPlaceholder="hh"
      minutePlaceholder="mm"
    />
  );
};

export default TimeInput;
