import React, { FC } from "react";
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  CardMedia,
  CardActions,
  Chip,
  CardHeader,
  Box,
  Typography,
  Avatar,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { GoalResponse, ActivityResponse } from "../../../types/types";
import ClampedTypography from "../../../components/common/ClampedTypography";

interface IGoalCard {
  goal: GoalResponse;
  activities: ActivityResponse[];
  onDeleteCallback: (id: string, name: string) => void;
  onEditCallback: (id: string) => void;
}
export const GoalCard: FC<IGoalCard> = ({
  goal,
  activities,
  onDeleteCallback,
  onEditCallback,
}) => {
  return (
    <Card variant="outlined">
      <CardHeader
        title={goal.name}
        action={
          <>
            <IconButton onClick={() => onDeleteCallback(goal.id, goal.name)}>
              <DeleteIcon color={"primary"} />
            </IconButton>
            <IconButton onClick={() => onEditCallback(goal.id)}>
              <EditIcon color="primary" />
            </IconButton>
          </>
        }
      />
      <Box sx={{ position: "relative" }}>
        <CardMedia
          component="img"
          height="180"
          image={goal.imageUrl}
          alt="Goal Image"
        />
        <Chip
          variant="filled"
          color="success"
          avatar={<Avatar src={goal.recommendedByImageUrl} />}
          label={`by ${goal.recommendedBy}`}
          sx={{ position: "absolute", bottom: 5, left: 5 }}
        ></Chip>
      </Box>
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <ClampedTypography
              text={goal.description}
              moreText="More Info"
              lessText="Less Info"
              lines={3}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Grid container spacing={3} style={{ justifyContent: "center" }}>
          {goal.tagNames.map((tag, index) => (
            <Grid key={index} item>
              <Chip
                label={tag}
                size="small"
                variant="outlined"
                color="primary"
              />
            </Grid>
          ))}
        </Grid>
      </CardActions>
    </Card>
  );
};
