import { Grid, Typography } from '@mui/material';

export interface ISupportProps {
}

const Support =  (props: ISupportProps ) => {
  return (
    <Grid container>
        <Grid item>
            <Typography>Support</Typography>
        </Grid>
    </Grid>
  );
}

export default Support;
