import React, {useEffect} from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";
import NavigationDrawer from "../drawer/NavigationDrawer";
import { useNavigate } from "react-router-dom";
import authSlice from "../../redux/reducers/authReducer";
import { persistor } from "../../redux/store";
import {useDispatch} from "react-redux";

const SUPER_ADMIN_ROUTES = ["/dashboard/customers"];

export default function ProtectedLayout() {
    const auth = useAppSelector(state => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        const checkUserGroups = async () => {
            if (auth.isLoggedIn && (!auth.user?.groups)) {
                console.error("User groups not set. Logging out.");
                await handleLogout();
            }
        };

        checkUserGroups();
    }, [auth.isLoggedIn, auth.user?.groups]);

    const handleLogout = async () => {
        dispatch(authSlice.actions.signOut());
        await persistor.purge();
        navigate("/signin", { replace: true });
    };

    if (auth.isLoading) {
        return <div>Loading...</div>; // Or a proper loading component
    }

    if (!auth.isLoggedIn) {
        return <Navigate to="/signin" state={{ from: location }} replace />;
    }

    const isSuperAdmin = auth.user?.groups?.superadmin;
    const isRestrictedRoute = SUPER_ADMIN_ROUTES.some(route => location.pathname.startsWith(route));

    if (isRestrictedRoute && !isSuperAdmin) {
        return <Navigate to="/dashboard" replace />;
    }

    return (
        <NavigationDrawer>
            <Outlet />
        </NavigationDrawer>
    );
}
