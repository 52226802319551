import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Grid,
  Pagination,
  TextField,
  Typography,
  CircularProgress,
  Fade,
} from "@mui/material";
import { useIntl } from "react-intl";
import { useGetTagsQuery } from "../../services/tags";
import TagCard from "./components/TagCard";
import AddTagDialog from "./components/AddTagDialog";
import usePagination from "../../hooks/usePagination";
import { TagResponse } from "../../types/types";

export interface ITagManagementProps {}

const PAGE_SIZE = 9;
const TagManagement = (props: ITagManagementProps) => {
  const intl = useIntl();
  const { data: tags, isLoading } = useGetTagsQuery();
  const [filterItems, setFilteredItems] = useState<TagResponse[]>([]);

  const [open, setOpen] = useState<boolean>(false);
  const handleOpenDialog = () => setOpen(true);
  const handleDialogClose = () => {
    setOpen(false);
  };
  const { start, end, handlePageChange, resetState} = usePagination(PAGE_SIZE);

  const handleFilterOptions = (
    e: React.SyntheticEvent<Element, Event>,
    value: string
  ) => {
    const search = value.toLowerCase();
    setFilteredItems(tags
      ?.filter(
        (val) =>
        search.length === 0 ||
          val.name.toLocaleLowerCase().indexOf(search) > -1 ||
          val.description.toLowerCase().indexOf(search) > -1
      ) ?? [])
      resetState();
  };

  useEffect(() => {
    setFilteredItems(tags ?? []);
  }, [tags])

  return (
    <Grid
      sx={{ pt: 2 }}
      container
      rowGap={2}
      spacing={3}
      justifyContent="flex-start"
    >
      <Grid item xs={12} md={8}>
        <Autocomplete
          disablePortal
          onInputChange={handleFilterOptions}
          id="group-selector"
          options={tags ?? []}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              label={intl.formatMessage({
                id: "Category.Common.HelpText.Search",
              })}
            />
          )}
          getOptionLabel={(option) => option.name}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.id}>
                <Typography variant="body1" color="text.secondary">
                  {option.name}
                </Typography>
              </li>
            );
          }}
        />
      </Grid>
      <Grid
        container
        item
        xs={12}
        md={4}
        alignSelf="center"
        justifyContent="flex-end"
      >
        <Button onClick={handleOpenDialog} variant="outlined">
          {intl.formatMessage({
            id: "Category.Common.HelpText.CreateCategory",
          })}
        </Button>
      </Grid>
      <Grid container item md={12} spacing={2}>
        {isLoading ? (
          <CircularProgress
            style={{
              position: "absolute",
              top: "50%",
              right: 0,
              bottom: 0,
              left: 0,
              margin: "0 auto",
            }}
          />
        ) : (
          filterItems
            .slice(start, end)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((tag) => (
              <Fade key={tag.id} in timeout={1000}>
                <Grid item xs={12} md={4}>
                  <TagCard tag={tag} />
                </Grid>
              </Fade>
            ))
        )}
      </Grid>
      <Grid container item alignSelf="center" justifyContent={"flex-end"}>
        <Pagination
          onChange={handlePageChange}
          count={Math.ceil((filterItems.length) / 9)}
        />
      </Grid>
      <AddTagDialog open={open} onClose={handleDialogClose} />
    </Grid>
  );
};

export default TagManagement;
