import {Typography} from "@mui/material";
import * as React from "react";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";
import Brandingbar from "../../components/brandingbar/brandingbar";
import styles from './home.module.scss';
import Button from "@mui/material/Button";

export interface IHomeProps {}

export default function Home(props: IHomeProps) {
    const matches = useMediaQuery("(min-width:600px)");
    const intl = useIntl();
    const navigate = useNavigate();

    return (
        <section>
            <div
                style={{
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    height: "100vh", width: "100vw",
                    position: 'fixed'
                }}
            >
            </div>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Brandingbar/>

                <div className={styles.homeContainer}>
                    <Typography
                        fontFamily={"Raleway"}
                        fontWeight={900}
                        variant={matches ? "h3" : "h4"}
                    >
                        Welcome to
                    </Typography>
                    <Typography
                        fontFamily={"Raleway"}
                        fontWeight={900}
                        color={"#FF3366"}
                        variant={matches ? "h3" : "h4"}
                    >
                        Masterment
                    </Typography>
                    <Box>
                        <p>The ultimate app for leaders who want to make a real difference! Whether you're a CEO, HR professional, or just passionate about leadership, Masterment gives you everything you need to grow, lead, and inspire.</p>
                        <p>With our easy-to-use tools, you can:</p>
                        <ul>
                            <li>Personalize Your Leadership Journey: Get tailored coaching powered by AI, helping you focus on what matters most to you.</li>
                            <li>Simplify ESG Reporting: Easily manage your Environmental, Social, and Governance (ESG) tasks with clear and simple tools.</li>
                            <li>Make Smart Decisions: Use real-time data to guide your actions and drive positive change in your organization.</li>
                            <li>Enjoy a User-Friendly Experience: Our app is designed to be intuitive and straightforward, so you can focus on leading, not learning how to use the app.</li>
                        </ul>
                        <p>Join a community of leaders committed to making the world a better place. Download Masterment now and start your journey toward impactful leadership and sustainable success!</p>
                    </Box>
                    <Box display="flex" justifyContent="center" mt={2}>
                        <a href="https://apps.apple.com/app/masterment/id6639605988" target="_blank" rel="noopener noreferrer">
                            <img src="/assets/application-marketplaces/apple-app-store.svg" alt="Download on the App Store" style={{height: 48, marginRight: 16}} />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=no.masterment.mastermentapp" target="_blank" rel="noopener noreferrer">
                            <img src="/assets/application-marketplaces/google-play-store.svg" alt="Get it on Google Play" style={{height: 48}} />
                        </a>
                    </Box>
                    <Box mt={2}>
                        <Button
                            variant="outlined"
                            component="a"
                            href="mailto:support@masterment.no"
                            sx={{
                                backgroundColor: "#FFF",
                                "&:hover": {
                                    backgroundColor: "rgba(255, 255, 255, 0.9)",
                                },
                                p: 1,
                                minWidth: 105,
                            }}
                        >
                            Contact us
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => navigate("/signin")}
                            sx={{
                                backgroundColor: "#FFF",
                                "&:hover": {
                                    backgroundColor: "rgba(255, 255, 255, 0.9)",
                                },
                                p: 1,
                                ml: 2,
                                minWidth: 105,
                            }}
                        >
                            {intl.formatMessage({id: "Commom.Label.Login"})}
                        </Button>
                    </Box>
                </div>
            </div>
        </section>
    );
}
